import { useEffect, useState, useRef } from "react";
import { useUser } from "../../contexts/UserContext";
import { callAPI } from "../../api";
import { GmailIcon, OutlookIcon } from "../Icons";
import {
  Typography,
  IconButton,
  ButtonGroup,
  Button,
  Box,
  CircularProgress,
  Divider,
  Radio,
} from "@mui/material";
import { Save } from "@mui/icons-material";
import { RichTextEditor } from "../../components/RichTextEditor";
import { Helper } from "../Helper";
import { AuthButton } from "./AuthButton";

const testEmail = (firstName, lastName) => `<div class="container"style="body {
    .container {
      background-color: #fff;
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      max-width: 300px;
      margin: 0 auto;
    }
    .header {
      font-size: 20px;
      margin-bottom: 20px;
    }
    .content {
      margin-bottom: 20px;
    }
    .footer {
      text-align: center;
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid #eee;
    }
    .logo {
      max-width: 100px;
      margin-bottom: 20px;
    }">
    <div class="header">
      Hi ${firstName} ${lastName},
    </div>
    <div class="content">
      This email is a confirmation that you can now send email from Bella. It is sent from your own address and uses your own email signature.
    </div>
    <div class="footer">
      Thank you,<br>
      Team Bella
      <a href="https://bellasales.io/">
  <img src="https://framerusercontent.com/images/pq17hV38jxHijIspTPlCzTfea0.png" alt="" srcset="https://framerusercontent.com/images/pq17hV38jxHijIspTPlCzTfea0.png?scale-down-to=512 512w, https://framerusercontent.com/images/pq17hV38jxHijIspTPlCzTfea0.png?scale-down-to=1024 1024w, https://framerusercontent.com/images/pq17hV38jxHijIspTPlCzTfea0.png 1299w" sizes="110px" style="display: block; width: 100px; max-height: 50px; border-radius: inherit; border: 5px solid #636ecd;object-position: center center; object-fit: cover; image-rendering: auto; background-color: #636ecd"></a>
    </div>
  </div>
  `;

export const EmailSignature = () => {
  const { userData, setUserData } = useUser();
  const [loading, setLoading] = useState(false);
  const emailSignatureRef = useRef(userData?.emailSignature);
  const update = async () => {
    setLoading(true);
    await callAPI("PUT", "email_signature", {
      signature: emailSignatureRef.current.toString(),
    })
      .then((res) => {
        setUserData({
          ...userData,
          emailSignature: res.signature,
        });
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body1" gutterBottom>
          Signature
        </Typography>
        {!loading ? (
          <Helper
            title={
              userData.linkedinObjectUrn &&
              emailSignatureRef.current?.length === 0
                ? "Add your signature. It will be used in all emails sent from Bella"
                : null
            }
            placement="bottom"
          >
            <IconButton onClick={update} sx={{ color: "#636ecd" }}>
              <Save />
            </IconButton>
          </Helper>
        ) : (
          <CircularProgress size={20} />
        )}
      </Box>
      <RichTextEditor
        contentRef={emailSignatureRef}
        style={{
          border: "1px solid #ccc",
          minHeight: "100px",
          padding: "10px",
          overflow: "auto",
        }}
      />
    </Box>
  );
};

export const EmailConnection: React.FC = () => {
  const { userData } = useUser();
  const [loading, setLoading] = useState(false);
  const [emailAddress, setEmailAddress] = useState<string>(null);
  const [emailSent, setEmailSent] = useState(false);
  const [provider, setProvider] = useState<"google" | "microsoft">(
    userData.emailProvider,
  );

  const changeProvider = () => {
    setProvider(provider === "google" ? "microsoft" : "google");
  };

  useEffect(() => {
    if (userData.emailProvider) {
      const getProfile = async () => {
        const res = await callAPI("GET", "email/profile");
        setEmailAddress(res.emailAddress);
      };
      getProfile();
    }
  }, [userData.emailProvider]);

  const sendTestEmail = async () => {
    setLoading(true);
    await callAPI("POST", "email", {
      to: userData.email,
      subject: "Test Email from Bella - Do No Reply",
      body: testEmail(userData.firstName, userData.lastName),
      content_type: "html",
    })
      .then((res) => {
        if (res.id) setEmailSent(true);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        <Radio
          checked={provider === "google"}
          onChange={changeProvider}
          value="a"
          name="radio-buttons"
          inputProps={{ "aria-label": "A" }}
        />
        <GmailIcon style={{ width: "1.5rem" }} />
        <Divider orientation="vertical" flexItem />
        <Radio
          checked={provider === "microsoft"}
          onChange={changeProvider}
          value="b"
          name="radio-buttons"
          inputProps={{ "aria-label": "B" }}
        />{" "}
        <OutlookIcon style={{ width: "1.5rem" }} />
      </Box>
      {userData.emailProvider === provider && emailAddress ? (
        <Typography variant="body2">
          You are authorized with {provider === "google" ? "Gmail" : "Outlook"}{" "}
          as <b>{emailAddress}</b>
        </Typography>
      ) : (
        provider && (
          <Typography variant="body2">
            You are not authorized with{" "}
            {provider === "google" ? "Gmail" : "Outlook"}.
          </Typography>
        )
      )}
      <br />
      {loading ? (
        <CircularProgress />
      ) : (
        provider && (
          <ButtonGroup
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AuthButton
              provider={provider}
              authorizedWith={userData.emailProvider}
            />
            <Button
              onClick={sendTestEmail}
              variant="contained"
              color="secondary"
              disabled={userData?.emailProvider !== provider || emailSent}
            >
              {emailSent ? "Test email sent" : "Send test email"}
            </Button>
          </ButtonGroup>
        )
      )}
    </Box>
  );
};
