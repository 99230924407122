import { useState, useEffect } from "react";
import { useCampaign } from "../../contexts/CampaignContext";
import { UpdateLeadsDialog } from "./UpdateLeadsDialog";

import { Helper } from "../../components/Helper";
import {
  Visibility,
  VisibilityOff,
  KeyboardArrowUp,
  KeyboardArrowDown,
  Search,
  FilterAlt,
  GroupAdd,
  AutoMode,
  StopCircle,
} from "@mui/icons-material";
import {
  Box,
  TablePagination,
  ButtonGroup,
  Popover,
  TextField,
  Button,
  IconButton,
  Stack,
  Fade,
  CircularProgress,
  Tooltip,
  Typography,
  LinearProgress,
} from "@mui/material";

import styles from "./Campaigns.module.scss";

const VisibilityButton = ({ showHidden }) => {
  return (
    <Tooltip title="Show Hidden Threads">
      <IconButton onClick={() => showHidden.set(!showHidden.get())}>
        {showHidden.get() ? (
          <Visibility color="primary" />
        ) : (
          <VisibilityOff color="secondary" />
        )}
      </IconButton>
    </Tooltip>
  );
};

const FilterButton = ({ order, orderBy }) => {
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(
    null,
  );

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!filterAnchorEl) {
      setFilterAnchorEl(event.currentTarget);
    } else {
      setFilterAnchorEl(null);
    }
  };
  return (
    <>
      <Tooltip title="Sort by Date">
        <IconButton
          id="filter-button"
          aria-controls={filterAnchorEl ? "filter-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={filterAnchorEl ? "true" : undefined}
          onClick={handleFilterClick}
        >
          <FilterAlt />
        </IconButton>
      </Tooltip>
      <Popover
        id="filter-menu"
        anchorEl={filterAnchorEl}
        open={Boolean(filterAnchorEl)}
        onClose={() => setFilterAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <ButtonGroup orientation="vertical">
          <Button
            color={orderBy.get() === "createdAt" ? "success" : "secondary"}
            onClick={() => {
              if (orderBy.get() === "createdAt") {
                order.set(order.get() === "asc" ? "desc" : "asc");
              } else {
                orderBy.set("createdAt");
                order.set("desc");
              }
            }}
            endIcon={
              orderBy.get() === "createdAt" ? (
                order.get() === "asc" ? (
                  <KeyboardArrowUp />
                ) : (
                  <KeyboardArrowDown />
                )
              ) : null
            }
          >
            Created Date
          </Button>
          <Button
            color={orderBy.get() === "updatedAt" ? "success" : "secondary"}
            onClick={() => {
              if (orderBy.get() === "updatedAt") {
                order.set(order.get() === "asc" ? "desc" : "asc");
              } else {
                orderBy.set("updatedAt");
                order.set("desc");
              }
            }}
            endIcon={
              orderBy.get() === "updatedAt" ? (
                order.get() === "asc" ? (
                  <KeyboardArrowUp />
                ) : (
                  <KeyboardArrowDown />
                )
              ) : null
            }
          >
            Last Update
          </Button>
        </ButtonGroup>
      </Popover>
    </>
  );
};

const SearchButton = ({ search }) => {
  const [searchAnchorEl, setSearchAnchorEl] = useState<null | HTMLElement>(
    null,
  );

  const handleSearchClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!searchAnchorEl) {
      setSearchAnchorEl(event.currentTarget);
    } else {
      setSearchAnchorEl(null);
    }
  };

  return (
    <>
      <Tooltip title="Search Threads">
        <IconButton
          id="search-button"
          aria-controls={searchAnchorEl ? "search-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={searchAnchorEl ? "true" : undefined}
          onClick={handleSearchClick}
        >
          {search.fullName.get() ||
          search.companyName.get() ||
          search.title.get() ? (
            <Search color="secondary" />
          ) : (
            <Search />
          )}
        </IconButton>
      </Tooltip>
      <Popover
        id="search-menu"
        anchorEl={searchAnchorEl}
        open={Boolean(searchAnchorEl)}
        onClose={() => setSearchAnchorEl(null)}
        TransitionComponent={Fade}
      >
        <Stack>
          <TextField
            id="search-lead-name"
            label="Full Name"
            type="search"
            variant="filled"
            size="small"
            value={search.fullName.get() || ""}
            onChange={(e) => search.fullName.set(e.target.value)}
          />
          <TextField
            id="search-company-name"
            label="Company Name"
            type="search"
            variant="filled"
            size="small"
            value={search.companyName.get() || ""}
            onChange={(e) => search.companyName.set(e.target.value)}
          />
          <TextField
            id="search-lead-title"
            label="Title"
            type="search"
            variant="filled"
            size="small"
            value={search.title.get() || ""}
            onChange={(e) => search.title.set(e.target.value)}
          />
        </Stack>
      </Popover>
    </>
  );
};

const LeadsUpdateButton = () => {
  const { campaignData, newLeads, newLeadsLoading } = useCampaign();
  const [showUpdateLeadsDialog, setShowUpdateLeadsDialog] = useState(false);
  return showUpdateLeadsDialog ? (
    <UpdateLeadsDialog
      open={showUpdateLeadsDialog}
      onClose={() => setShowUpdateLeadsDialog(false)}
    />
  ) : (
    <Helper
      title={
        campaignData?.threads?.length === 0 &&
        "First, import your leads from the the Sales Navigator"
      }
      placement="bottom"
    >
      <Tooltip
        title={
          newLeads?.length > 0
            ? `Sync Leads: Found ${newLeads?.length} new leads.`
            : "Sync Leads: No new leads found"
        }
      >
        <span>
          <IconButton
            onClick={() => setShowUpdateLeadsDialog(true)}
            disabled={newLeadsLoading || newLeads?.length === 0}
            {...(newLeads?.length > 0
              ? { color: "secondary" }
              : { disabled: true })}
          >
            {newLeadsLoading ? <CircularProgress size={20} /> : <GroupAdd />}
          </IconButton>
        </span>
      </Tooltip>
    </Helper>
  );
};

const AutomationButton = () => {
  const { runAutomation } = useCampaign();
  return (
    <>
      <Tooltip title="Run Automation">
        <IconButton onClick={runAutomation}>
          <AutoMode color="primary" />
        </IconButton>
      </Tooltip>
    </>
  );
};

const AutomationProgress = () => {
  const { stopAutomation, automationProgress } = useCampaign();
  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
      <IconButton onClick={() => stopAutomation()}>
        <StopCircle color="warning" />
      </IconButton>
      <Box sx={{ width: "100%" }}>
        <Typography variant="caption" color="secondary">
          Automation is running...
        </Typography>
        <LinearProgress
          variant="buffer"
          value={Math.round(100 * automationProgress)}
          valueBuffer={1}
        />
      </Box>
    </Box>
  );
};

export const Navigation = (props: {
  count;
  page;
  setPage;
  rowsPerPage;
  setRowsPerPage;
}) => {
  const { count, page, setPage, rowsPerPage, setRowsPerPage } = props;

  useEffect(() => {
    setPage(0);
    setRowsPerPage(10);
  }, [setPage, setRowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  return (
    <Box className={styles.pagination}>
      <TablePagination
        component="div"
        count={count}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 20]}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </Box>
  );
};

export const CampaignToolbar = ({ toolbarProps }) => {
  const { campaignData } = useCampaign();
  return (
    <Box className={styles.campaignToolbar} p={1} ml={2}>
      {campaignData.automationRunning.get() ? (
        <AutomationProgress />
      ) : (
        <>
          <ButtonGroup
            disableElevation
            variant="contained"
            aria-label="Disabled button group"
          >
            <VisibilityButton showHidden={toolbarProps.showHidden} />
            <FilterButton
              order={toolbarProps.order}
              orderBy={toolbarProps.orderBy}
            />
            <SearchButton search={toolbarProps.search} />
            <LeadsUpdateButton />
            <AutomationButton />
          </ButtonGroup>
        </>
      )}
    </Box>
  );
};
