import { useState } from "react";
import { callAPI } from "../api";
import {
  Box,
  Modal,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useThread } from "../contexts/ThreadContext";

const modalStyle = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const HubSpotModal = (props: { open; setOpen }) => {
  const [loading, setLoading] = useState(false);
  const { thread } = useThread();
  const [message, setMessage] = useState(null);

  const importLeadToHubSpot = async () => {
    setLoading(true);
    const res = await callAPI("POST", "/crm/lead", {
      leadPID: thread.target?.pid,
    });

    setLoading(false);

    if (res) {
      setMessage("Successfully Imported Lead to HubSpot");
      setTimeout(() => {
        props.setOpen(false);
        setMessage(null);
      }, 2000);
    } else {
      console.error("Failed to import lead to HubSpot");
    }
  };

  return (
    <Modal open={props.open} onClose={() => props.setOpen(false)}>
      <Box sx={modalStyle}>
        {loading ? (
          <CircularProgress />
        ) : (
          <Box>
            {message ? (
              <Typography>{message}</Typography>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  alignItems: "center",
                }}
              >
                {!thread?.target.hubSpotLeadId ? (
                  <>
                    <Typography variant="h6">
                      Import Lead data to HubSpot
                    </Typography>
                    <Button onClick={importLeadToHubSpot} variant="outlined">
                      Import Lead to HubSpot
                    </Button>
                  </>
                ) : (
                  <>
                    <Typography variant="h6">
                      Update Lead data in HubSpot
                    </Typography>
                    <Button onClick={importLeadToHubSpot} variant="outlined">
                      Update Lead in HubSpot
                    </Button>
                  </>
                )}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Modal>
  );
};
