import { useUser } from "../contexts/UserContext";
import { Box, Modal } from "@mui/material";

const modalStyle = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const viteEnv = import.meta.env.VITE_ENV;

export const ShopModal = (props: { showModal; setShowModal }) => {
  const { userData } = useUser();
  return (
    <Modal open={props.showModal} onClose={() => props.setShowModal(false)}>
      <Box sx={modalStyle}>
        {viteEnv === "dev" ||
        viteEnv === "test" ||
        window.location.hostname.startsWith("dev.") ? (
          <stripe-buy-button
            buy-button-id="buy_btn_1P3G1fS63bcFdplCEMeKb4R9"
            publishable-key="pk_test_51OoGa7S63bcFdplCqxngMqPQweAQ4HTyNf7K3aPzHSoy4hmNjvqWxsy5IlCN17gNC4TvwI8uRSfnQuHAJZhVajFa00MJa8yG27"
            client-reference-id={userData.pid}
            customer-email={userData.email}
          ></stripe-buy-button>
        ) : (
          <stripe-buy-button
            buy-button-id="buy_btn_1P3Q6hS63bcFdplC0hkOb7Pd"
            publishable-key="pk_live_51OoGa7S63bcFdplC9Fq85UJCmqTOQ0Cq6hUfvfAq7eaUo2qJxLLP6SGh6hGYFfLYUQh1L4SGJsWeQ6QPeW3SX3Pd00GeaK4I90"
            client-reference-id={userData.pid}
            customer-email={userData.email}
          ></stripe-buy-button>
        )}
      </Box>
    </Modal>
  );
};
