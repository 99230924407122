import { createContext, useContext, useState } from "react";

import { User } from "../components/types";
import { callAPI } from "../api";

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState<User>(null);
  const [activePage, setActivePage] = useState("campaigns");

  const fetchProfile = async () => {
    const newData = await callAPI("GET", "user");
    if (newData.error) {
      throw new Error(newData.error);
    }
    localStorage.setItem("userData", JSON.stringify(newData));
    setUserData({
      ...newData,
      threads: [],
    });
  };

  const updateUser = async (props) => {
    const res = await callAPI("PUT", "/user", props);
    setUserData(res);
  };

  return (
    <UserContext.Provider
      value={{
        userData,
        setUserData,
        fetchProfile,
        activePage,
        setActivePage,
        updateUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
export const useUser = () => useContext(UserContext);
