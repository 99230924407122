import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { useHookstate } from "@hookstate/core";
import { callAPI, callExtension } from "../api";
import { useUser } from "./UserContext";
import { useCampaign } from "./CampaignContext";
import { Thread, SequenceStep } from "../components/types";

const viteEnv = import.meta.env.VITE_ENV;

export const ThreadContext = createContext(null);

export const getAgeDays = (start, end) => {
  const diff = (end || Date.now() / 1000) - start;
  const age = diff / 60 / 60 / 24;
  return age;
};

const getEmailHref = ({ emailAddress, subject, body }) =>
  `mailto:${encodeURIComponent(emailAddress)}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

export const ThreadProvider = ({ children, threadPID, automationStatus }) => {
  const { userData } = useUser();

  const { changeThreadVisibility, sequence } = useCampaign();
  const threadData = useHookstate<Thread>({
    messages: [],
    insights: [],
    msgIndex: 0,
  } as Thread);

  // ! Thread Load
  useEffect(() => {
    const loadThread = async () => {
      const res = await callAPI("GET", "thread", {
        threadPID: threadPID,
      });
      if (res) {
        try {
          threadData.set({
            ...res,
            insights: res.insights.filter((i) => i.origin),
            messages: res.messages
              .filter((m) => m.pid)
              .sort((a, b) => a.updatedAt - b.updatedAt),
            msgIndex: res.messages.length - 1,
          });
        } catch (e) {
          // Ignore HOOKSTATE-106 error. This error says that hookstate is still loading while the component already destroyed. It's not am urgent problem. You can fix it later.
          if (!e.message.startsWith("Error: HOOKSTATE-106")) {
            console.error(e);
          }
        }
      }
    };

    if (!threadData.value?.pid) {
      loadThread();
    }
  }, [threadPID, threadData.pid]);

  // ! Memoized values

  const lead = useMemo(() => threadData.target.get(), [threadData.target]);
  const currentMessage = threadData.messages[threadData.msgIndex.get()];

  // ! Thread State

  const updateMessage = (message) =>
    threadData.messages.find((m) => m.pid.get() === message.pid).set(message);

  const putLead = useCallback(async (props) => {
    const res = await callAPI("PUT", "lead", {
      leadPID: threadData.target.pid.get(),
      ...props,
    });
    threadData.target.set(res);
  }, []);

  const changeVisibility = async () => {
    const res = await callAPI("PUT", "thread/visibility", {
      threadPID: threadData.pid.get(),
    });
    threadData.hidden.set(res.hidden);
    changeThreadVisibility(threadPID, res.hidden);
  };

  // ! Synchronizations

  // const checkLisnThread = async () => {
  //   const res = await callExtension("LISN_fetchThreadMessages", {
  //     lisnThreadId: threadData.target.lisnThreadId.get(),
  //   });
  //   threadData.messages.merge(res);
  // };

  const completeStep = async (stepPID) => {
    const res = await callAPI("PUT", "thread/step", {
      threadPID: threadData.pid.get(),
      stepPID: stepPID,
    });
    threadData.completedSteps.set(res.completedSteps);
    return true;
  };

  // ! Research

  const research = async (stepPID) => {
    const res = await callAPI("POST", "llm/research", {
      leadPID: lead.pid,
    });
    // Let's view their profile before generating a message. It imitates research activity.
    await viewProfile();
    threadData.insights.set(res);
    const requiredInsights = [
      "company_linkedin",
      "lead_linkedin",
      "industry_news",
    ];
    if (requiredInsights.every((ri) => res.map((i) => i.origin).includes(ri))) {
      const res = await completeStep(stepPID);
      return res;
    }
    return false;
  };

  // ! Messaging
  const generateMessage = async (
    messageType: string,
    channel: string,
    stepPID: string,
  ) => {
    if (messageType === "cr" && threadData.target?.degree.get() === 1) return;
    // Generates both CR and Messages
    const res = await callAPI("POST", "llm/message", {
      type: messageType,
      channel: channel,
      leadPID: threadData.target.pid.get(),
      stepPID: stepPID,
    });
    if (res.error) {
      throw new Error(res.error);
    }
    threadData.messages.merge([res]);
    threadData.msgIndex.set(threadData.messages.length - 1);
    currentMessage.set(res);
    return true;
  };

  const sendCR = async () => {
    // Used for sending CR in LISN and updating message status in DB
    if (
      lead.degree === 1 ||
      currentMessage.type.get() !== "cr" ||
      currentMessage.status.get() !== "created"
    ) {
      return false;
    }
    const res = await callExtension(
      "LISN_sendCR",
      {
        leadPID: lead.pid,
        messagePID: currentMessage?.pid.get(),
        msgBody: currentMessage.content.get(),
      },
      {},
    );
    if (!res?.pid) return false;
    currentMessage.set(res);
    discardOtherMessagesOfTheType(res);
    await completeStep(currentSteps.find((s) => s.messageType === "cr").pid);
    return true;
  };

  const putMessage = async (props, stepPID?) => {
    const res = await callAPI("PUT", "thread/message", {
      messagePID: currentMessage.pid.get(),
      props: props,
    });
    updateMessage(res);
    if (stepPID) {
      await completeStep(stepPID);
    }
  };

  // Only used for Non-pregenerated messages
  const postMessage = async (props) => {
    const res = await callAPI("POST", "thread/message", {
      threadPID: threadData.pid.get(),
      props: props,
    });
    if (!res?.pid) return false;
    threadData.messages.merge([res]);
    threadData.msgIndex.set(threadData.messages.length - 1);
    return res;
  };

  const deleteMessage = async (messagePID) => {
    const res = await callAPI("DELETE", "thread/message", {
      messagePID: messagePID,
    });
    if (res.status === "success") {
      threadData.msgIndex.set((i) => (i > 0 ? i - 1 : 0));
      threadData.messages.set((messages) =>
        messages.filter((m) => m.pid !== messagePID),
      );
    }
  };

  const sendMessage = async (stepPid) => {
    if (
      currentMessage.type.get() === "cr" ||
      currentMessage.status.get() !== "created"
    ) {
      return;
    }
    // First, lets like the latest post if there is one
    // await likeLatestPost();
    const res = await callExtension(
      "LISN_sendMessage",
      {
        leadPID: lead.pid,
        messagePID: currentMessage.pid.get(),
        msgBody: currentMessage.content.get(),
        msgSubject: currentMessage.subject.get(),
      },
      {},
    );
    if (!res?.pid) return false;
    discardOtherMessagesOfTheType(res);
    await completeStep(stepPid);
    return true;
  };

  const sendEmail = async (stepPid) => {
    if (!userData.emailSignature || !threadData.target?.email.get()) {
      return;
    }
    if (userData.emailProvider) {
      if (viteEnv === "test") {
        console.log("Sending email to", lead.email, currentMessage.get());
        return;
      }
      const res = await callAPI("POST", "email", {
        to: lead.email,
        subject: currentMessage.subject.get(),
        body: currentMessage.content.get(),
        content_type: "html",
        messagePID: currentMessage.pid.get(),
        leadPID: lead.pid,
      });
      if (!res?.pid) return false;
      discardOtherMessagesOfTheType(res);
      await completeStep(stepPid);
    } else {
      window.open(
        getEmailHref({
          emailAddress: lead.email,
          subject: currentMessage.subject.get(),
          body: currentMessage.content.get(),
        }),
      );
    }
  };

  const archiveCallScript = async () => {
    await putMessage({
      status: "sent",
      content: currentMessage.content.get(),
    });
  };

  const discardOtherMessagesOfTheType = (message) => {
    threadData.messages.set((messages) =>
      messages.map((m) =>
        m.pid === message.pid
          ? message
          : {
              ...m,
              status:
                m.type === message.type && m.channel && message.channel
                  ? "discarded"
                  : m.status,
            },
      ),
    );
  };

  const crSent = threadData.messages.find(
    (m) => m.type.get() === "cr" && m.status.get() === "sent",
  );

  const crAccepted = threadData.messages.find(
    (m) => m.type.get() === "cr" && m.status.get() === "accepted",
  );

  const emailOutreachSent = threadData.messages.filter(
    (m) =>
      m.type.get() === "outreach" &&
      m.status.get() === "sent" &&
      m.channel.get() === "email",
  );

  const linkedInOutreachSent = threadData.messages.filter(
    (m) =>
      m.type.get() === "outreach" &&
      m.status.get() === "sent" &&
      m.channel.get() === "linkedin",
  );

  const replyReceived = threadData.messages.filter(
    (m) => m.type.get() === "reply" && m.status.get() === "received",
  );

  // ! Other LinkedIn Actions
  const viewProfile = async () => {
    const publicId = lead.linkedInUrl.split("/").pop();
    const res = await callExtension("LI_viewProfilePage", {
      publicId: publicId,
    });
    if (res.success) {
      console.log(`Viewed ${publicId} profile successfully`);
    }
  };

  // const likeLatestPost = async () => {
  //   const publicId = lead.linkedInUrl.split("/").pop();
  //   const res = await callExtension("LI_likeLatestPost", {
  //     profileObjectUrn: lead.linkedinObjectUrn,
  //   });
  //   if (res.success) {
  //     console.log(`Liked latest post of ${publicId} successfully`);
  //   }
  // };

  // ! Sequence
  const sequenceSteps = sequence.steps.get();

  const getNextSteps = (step: SequenceStep) => {
    if (step.nodeType === "research") return step.nextSteps.map((ns) => ns.pid);

    if (step.nodeType === "linkedinCR" && linkedInOutreachSent?.length > 1)
      return [];

    const message = threadData.messages.find(
      (m) => m.step_pid.get() === step.pid,
    );

    if (!message) return [];
    const daysDiff = getAgeDays(message.sentAt.get(), message.repliedAt.get());
    const replied = ["replied", "accepted"].includes(message.status.get());
    const nextSteps = [];
    if (replied) {
      step.nextSteps.forEach((ns) => {
        if (ns.sourceHandle === "positive" && daysDiff >= ns.days) {
          nextSteps.push(ns.pid);
        }
      });
    } else {
      step.nextSteps.forEach((ns) => {
        if (ns.sourceHandle === "negative" && !replied && daysDiff >= ns.days) {
          nextSteps.push(ns.pid);
        }
      });
    }
    return nextSteps;
  };

  const currentSteps = useMemo(() => {
    if (!threadData.completedSteps) return [];
    const completedSteps = sequenceSteps.filter((s) =>
      (threadData.completedSteps.get() || []).includes(s.pid),
    );
    const futureSteps = sequenceSteps.filter(
      (s) => !completedSteps.map((s) => s.pid).includes(s.pid),
    );
    if (completedSteps.length === 0) {
      return futureSteps.filter((s) => s.nodeType === "research");
    }

    const nextSteps = completedSteps
      .flatMap((s) => getNextSteps(s))
      .filter((pid) => !threadData.completedSteps.get().includes(pid));

    const res = futureSteps.filter((s) => nextSteps.includes(s.pid));

    return res;
  }, [threadData.completedSteps]);

  return (
    <ThreadContext.Provider
      value={{
        threadData,
        lead,
        currentMessage,
        updateMessage,
        putMessage,
        postMessage,
        deleteMessage,
        putLead,
        changeVisibility,
        generateMessage,
        sendCR,
        sendMessage,
        sendEmail,
        archiveCallScript,
        research,
        currentSteps,
        crSent,
        crAccepted,
        emailOutreachSent,
        linkedInOutreachSent,
        replyReceived,
        automationStatus,
      }}
    >
      {threadData.pid.get() && children}
    </ThreadContext.Provider>
  );
};

export const useThread = () => useContext(ThreadContext);
