import { useCallback, useEffect, useState } from "react";
import { useUser } from "../../contexts/UserContext";
import ReactFlow, {
  MarkerType,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  BackgroundVariant,
  Edge,
} from "reactflow";

import {
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Box,
  IconButton,
  TextField,
  colors,
  Modal,
  CircularProgress,
  Typography,
  Chip,
} from "@mui/material";
import { Save, Done } from "@mui/icons-material";

import "reactflow/dist/style.css";
import {
  nodeTypes,
  edgeTypes,
  templateTypes,
  nodesInitData,
  addNodeAction,
  generateNode,
} from "./types";
import { callAPI } from "../../api";
import { Sequence } from "../../components/types";

import { defaultNodes, defaultEdges } from "./defaultSequence";

interface SequenceBuilderProps {
  sequence: Sequence;
  exit: () => void;
}

const restoreNodes = (sequence: Sequence) =>
  sequence.steps.map((step) => ({
    ...generateNode(
      step.nodeType,
      step.nodeId.split("-")[1],
      {
        ...nodesInitData[step.nodeType],
        chosenTemplate: {
          ...templateTypes[step.templatePrefix],
          content: step.templateContent,
        },
        productsAndServicesIdxs: step.productsAndServicesIdxs,
        caseStudiesIdxs: step.caseStudiesIdxs,
        customInstructions: step.customInstructions,
        autoGenerate: step.autoGenerate,
        autoSend: step.autoSend,
      },
      step.position,
    ),
  }));

const restoreEdges = (sequence: Sequence) =>
  sequence.steps
    .map((step) =>
      step.nextSteps.map((nextStep) => ({ ...nextStep, source: step.nodeId })),
    )
    .flat()
    .map(
      (edge) =>
        ({
          id: `reactflow__edge-${edge.source}${edge.sourceHandle}-${edge.target}${edge.targetHandle}`,
          source: edge.source,
          sourceHandle: edge.sourceHandle,
          target: edge.target,
          targetHandle: edge.targetHandle,
          data: { days: edge.days },
        }) as Edge,
    );

export function SequenceBuilder(props: SequenceBuilderProps) {
  const { userData, setUserData } = useUser();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [sequenceName, setSequenceName] = useState(null);
  const [showSequenceNameInput, setShowSequenceNameInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const [removedNodes, setRemovedNodes] = useState<string[]>([]);

  const loadSequence = async () => {
    setLoading(true);
    try {
      const res: Sequence = await callAPI("GET", `sequence`, {
        sequencePID: props.sequence.pid,
      });
      setSequenceName(res.name);
      const nodes = restoreNodes(res);
      const edges = restoreEdges(res);
      setNodes(nodes);
      setEdges(edges);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (props.sequence?.pid) {
      loadSequence();
    } else {
      setNodes(defaultNodes);
      setEdges(defaultEdges);
    }
  }, [props.sequence?.pid]);

  const addActions: addNodeAction[] = [
    {
      id: "linkedinCR",
      name: "LinkedIn connection request",
      disabled: nodes.filter((node) => node.type === "linkedinCR").length >= 1,
    },
    {
      id: "linkedinOutreach",
      name: "LinkedIn outreach message",
      disabled:
        nodes.filter((node) => node.type === "linkedinOutreach").length >= 3,
    },
    {
      id: "linkedinInMail",
      name: "LinkedIn outreach InMail",
      disabled:
        nodes.filter((node) => node.type === "linkedinInMail").length >= 1,
    },
    {
      id: "emailOutreach",
      name: "Email outreach",
      disabled:
        nodes.filter((node) => node.type === "emailOutreach").length >= 3,
    },
    // {
    //   id: "viewProfile",
    //   name: "View profile",
    //   disabled: nodes.filter((node) => node.type === "viewProfile").length >= 3,
    // },
    // {
    //   id: "likePost",
    //   name: "Like latest post",
    //   disabled: nodes.filter((node) => node.type === "likePost").length >= 1,
    // },
    // {
    //   id: "inviteToFollow",
    //   name: "Invite to follow your company",
    //   disabled:
    //     nodes.filter((node) => node.type === "inviteToFollow").length >= 1,
    // },
  ];

  const onConnect = useCallback(
    (params) => {
      setErrors([]);
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            markerEnd: {
              type: MarkerType.ArrowClosed,
              width: 20,
              height: 20,
              color: params.sourceHandle === "positive" ? "#3f51b5" : "#f44336",
            },
          },
          eds,
        ),
      );
    },
    [setEdges],
  );

  const checkNodes = () => {
    const errors = [];
    nodes.forEach((node) => {
      if (
        node.type !== "research" &&
        !edges.find((e) => e.target === node.id)
      ) {
        errors.push(`Node ${node.data.label} is missing input connection`);
      }
    });
    if (errors.length > 0) {
      setErrors(errors);
      return false;
    }
    return true;
  };

  const handleSave = async () => {
    if (!checkNodes()) {
      return;
    }
    if (!showSequenceNameInput) {
      setShowSequenceNameInput(true);
    } else {
      try {
        setLoading(true);
        const res = await callAPI(
          props.sequence.pid ? "PUT" : "POST",
          "sequence",
          {
            pid: props.sequence?.pid,
            name: sequenceName,
            nodes: nodes.map((node) => ({
              nodeId: node.id,
              nodeType: node.type,
              channel: node.data.channel,
              messageType: node.data.messageType,
              templatePrefix: node.data.chosenTemplate?.prefix,
              templateContent: node.data.chosenTemplate?.content,
              autoGenerate: node.data.autoGenerate,
              autoSend: node.data.autoSend,
              position: node.position,
              productsAndServicesIdxs: node.data.productsAndServicesIdxs,
              caseStudiesIdxs: node.data.caseStudiesIdxs,
              customInstructions: node.data.customInstructions,
            })),
            edges: edges.map((edge) => ({
              days: edge.data?.days || 0,
              source: edge.source,
              sourceHandle: edge.sourceHandle,
              target: edge.target,
              targetHandle: edge.targetHandle,
            })),
            removedNodes: removedNodes.filter(
              (nodeId) => !nodes.map((n) => n.id).includes(nodeId),
            ),
          },
        );
        if (props.sequence.pid) {
          setUserData({
            ...userData,
            sequences: userData.sequences.map((seq) =>
              seq.pid === props.sequence.pid ? res : seq,
            ),
          });
        } else {
          setUserData({
            ...userData,
            sequences: [...userData.sequences, res],
          });
        }
        setShowSequenceNameInput(false);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
        props.exit();
      }
    }
  };

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      nodeTypes={nodeTypes}
      onEdgesChange={onEdgesChange}
      edgeTypes={edgeTypes}
      onConnect={onConnect}
      fitView={true}
      minZoom={0.7}
      maxZoom={1}
      snapToGrid={true}
      snapGrid={[50, 50]}
      translateExtent={[
        [0, 0],
        [0, 0],
      ]}
      elevateEdgesOnSelect={true}
      fitViewOptions={{ padding: 20 }}
      connectionLineStyle={{ strokeWidth: 3 }}
      onDoubleClick={(e) => e.stopPropagation()}
      onNodesDelete={(deleted) =>
        setRemovedNodes([...removedNodes, ...deleted.map((d) => d.id)])
      }
    >
      <Modal open={loading} onClose={() => {}}>
        <Box
          sx={{
            position: "absolute" as const,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            p: 4,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 5,
          }}
        >
          <CircularProgress />
          <Typography>Loading...</Typography>
        </Box>
      </Modal>
      {errors.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            position: "absolute",
            top: 5,
            right: 5,
            zIndex: 1200,
          }}
        >
          {errors.map((e, idx) => (
            <Chip label={e} color="warning" key={idx} />
          ))}
        </Box>
      )}
      <Controls />
      <Background variant={BackgroundVariant.Lines} gap={50} />
      <Box
        sx={{
          position: "absolute",
          bottom: 16,
          right: 80,
          width: showSequenceNameInput ? "auto" : 56,
          height: 56,
          backgroundColor: colors.green[500],
          borderRadius: 20,
          padding: 1,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          zIndex: 1500,
        }}
        // onMouseLeave={() => setShowSequenceNameInput(false)}
      >
        {showSequenceNameInput && (
          <TextField
            label="Sequence Name"
            size="small"
            sx={{ marginLeft: 5, marginRight: 5 }}
            value={sequenceName || ""}
            onChange={(e) => setSequenceName(e.target.value)}
            onBlur={() => setShowSequenceNameInput(false)}
          />
        )}
        <IconButton aria-label="delete" size="large" onClick={handleSave}>
          {showSequenceNameInput ? (
            <Done sx={{ color: "white" }} />
          ) : (
            <Save sx={{ color: "white" }} />
          )}
        </IconButton>
      </Box>
      <SpeedDial
        ariaLabel="Add another step"
        sx={{ position: "absolute", bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
      >
        {addActions.map((action) => (
          <SpeedDialAction
            key={action.id}
            icon={nodesInitData[action.id].icon}
            tooltipTitle={
              <Box sx={{ whiteSpace: "nowrap" }}>{action.name}</Box>
            }
            FabProps={{ disabled: action.disabled }}
            onClick={() =>
              !action.disabled &&
              setNodes([...nodes, generateNode(action.id, nodes.length)])
            }
            tooltipOpen
          />
        ))}
      </SpeedDial>
    </ReactFlow>
  );
}
