import { useState } from "react";
import { callExtension } from "../../api";
import { useUser } from "../../contexts/UserContext";
import { useCampaign } from "../../contexts/CampaignContext";
import styles from "./Campaigns.module.scss";

import {
  Box,
  Button,
  LinearProgress,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  ButtonGroup,
  Tooltip,
  Fade,
} from "@mui/material";

import { Info } from "@mui/icons-material";

export const pause = async (duration: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, duration));
};

const scanProfileLinkedInData = async (leadEntityUrn, setUpdate) => {
  setUpdate("Scanning LinkedIn profile...");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const profile: any = await callExtension("LISN_fetchLead", {
    entityUrn: leadEntityUrn,
  });
  setUpdate(`${profile.firstName} ${profile.lastName}: Scanning activities...`);
  const activities = await callExtension("LISN_fetchLeadActivities", {
    entityUrn: leadEntityUrn,
  });
  const companyName = profile.defaultCompanyName;
  const companyUrn = profile.defaultCompanyUrn;
  setUpdate(
    `${profile.firstName} ${profile.lastName}(${companyName}): Scanning company...`,
  );
  const companyData = await callExtension("LISN_fetchCompany", {
    entityUrn: companyUrn,
  });
  setUpdate(
    `${profile.firstName} ${profile.lastName}(${companyName}): Scanning company activities...`,
  );
  const companyActivitiesData = await callExtension(
    "LISN_fetchCompanyActivities",
    { entityUrn: companyUrn },
  );
  setUpdate(
    `${profile.firstName} ${profile.lastName}(${companyName}): Scanning company job postings...`,
  );
  const companyJobsData = await callExtension("LI_fetchCompanyJobs", {
    entityUrn: companyUrn,
  });
  return {
    firstName: profile.firstName,
    lastName: profile.lastName,
    title: profile.companies.find(
      (c) => c.objectUrn === profile.defaultCompanyUrn,
    ).title,
    linkedinObjectUrn: profile.objectUrn,
    linkedin: {
      ...profile,
      activities: activities,
    },
    company: {
      name: companyData.name,
      website: companyData.website,
      location: companyData.location,
      linkedinObjectUrn: companyData.objectUrn,
      linkedin: {
        ...companyData,
        activities: companyActivitiesData,
        jobPostings: companyJobsData,
      },
    },
    pendingInvitation: profile.pendingInvitation,
    openProfile: profile.openProfile,
    degree: profile.degree,
  };
};

export const UpdateLeadsDialog = (props: {
  open: boolean;
  onClose: () => void;
}) => {
  const { open, onClose } = props;
  const { userData } = useUser();
  const { newLeads, setNewLeads, loadData, postLead } = useCampaign();
  const [update, setUpdate] = useState<string | null>(null);
  const [failed, setFailed] = useState<string[]>([]);
  const [processing, setProcessing] = useState(false);
  const [progress, setProgress] = useState(0);

  const finish = async () => {
    await loadData();
    setProcessing(false);
    setUpdate(null);
    setFailed([]);
    setNewLeads([]);
    onClose();
  };

  const addLeads = async () => {
    setProgress(0.5);
    for (const lead of newLeads) {
      try {
        if (userData.leadCredits - userData.totalLeads <= 0) {
          setUpdate(
            "Not enough credits to process. Please purchase more lead credits.",
          );
          await pause(2000);
          break;
        }
        const scannedLead = await scanProfileLinkedInData(
          lead.entityUrn,
          setUpdate,
        );
        setUpdate("Loading to Bella");
        const creditsLeft = await postLead(scannedLead);
        console.log("Credits left:", creditsLeft);
      } catch (error) {
        console.log(error);
        setFailed((prev) => [...prev, lead.fullName]);
      } finally {
        setProgress((prev) => prev + 1);
      }
    }
    await finish();
  };

  const handleProcess = async () => {
    setProcessing(true);
    await addLeads();
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      className={styles.updateLeadDialog}
    >
      <DialogTitle sx={{ position: "relative" }}>
        Add new leads from LinkedIn Sales Navigator
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            color: "primary.main",
          }}
        >
          <Tooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            title={
              <Typography variant="body2">
                This dialog helps you to update your leads in the campaign. It
                seems you have {newLeads?.length} new leads in your Sales
                Navigator. Click on the "Import Leads List" button to start the
                process.
                <br />
                <br />
                Some leads might fail to process due to missing basic required
                information on LinkedIn, such as summaries, company page or a
                website.
              </Typography>
            }
          >
            <Info />
          </Tooltip>
        </Box>
      </DialogTitle>
      {newLeads && (
        <DialogContent className={styles.updateLeadDialogContent}>
          <Typography>Found {newLeads.length} new profiles</Typography>
          <ButtonGroup>
            <Button
              onClick={handleProcess}
              variant="outlined"
              disabled={processing}
              color={processing ? "secondary" : "primary"}
            >
              Import Leads List
            </Button>
            <Button
              onClick={onClose}
              variant="outlined"
              disabled={processing}
              color="secondary"
            >
              Cancel
            </Button>
          </ButtonGroup>
          {update && (
            <Box className={styles.updateLeadDialogStatusBox}>
              <Typography
                id="list-panel-updates-title"
                variant="h6"
                component="h2"
              >
                Processing Leads
              </Typography>
              {processing && (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress
                    variant="determinate"
                    value={(100 * progress) / newLeads?.length}
                  />
                </Box>
              )}
              <Typography id="list-panel-updates-description" sx={{ mt: 2 }}>
                {update}
              </Typography>
              {failed.length > 0 && (
                <Box>
                  <Typography id="list-panel-fails-description" sx={{ mt: 2 }}>
                    Failed to process:
                  </Typography>
                  <ul>
                    {failed.map((f) => (
                      <li key={f}>{f}</li>
                    ))}
                  </ul>
                </Box>
              )}
            </Box>
          )}
        </DialogContent>
      )}
    </Dialog>
  );
};
