import { useEffect, useState } from "react";
import { useUser } from "../../contexts/UserContext";
import { callAPI } from "../../api";
import { HubSpotLogoIcon } from "../Icons";
import { AuthButton } from "./AuthButton";
import { Typography, ButtonGroup, Box, CircularProgress } from "@mui/material";

export const CRMIntegration: React.FC = () => {
  const { userData } = useUser();
  const [loading, setLoading] = useState(false);
  const [provider] = useState<string>(userData.crmProvider || "hubspot");
  const [crmUser, setCrmUser] = useState(null);

  useEffect(() => {
    if (userData.crmProvider) {
      setLoading(true);
      const getProfile = async () => {
        const res = await callAPI("GET", "crm/profile");
        setCrmUser(res.user);
      };
      getProfile();
      setLoading(false);
    }
  }, [userData.crmProvider]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        <HubSpotLogoIcon style={{ width: "4rem" }} />
      </Box>
      {userData.crmProvider === provider && crmUser ? (
        <Typography variant="body2">
          You are authorized with HubSpot as <b>{crmUser}</b>
        </Typography>
      ) : (
        <Typography variant="body2">
          You are not authorized with HubSpot.
        </Typography>
      )}
      <br />
      {loading ? (
        <CircularProgress />
      ) : (
        <ButtonGroup
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          disabled={loading || !provider}
        >
          <AuthButton
            provider={provider}
            authorizedWith={userData.crmProvider}
          />
        </ButtonGroup>
      )}
    </Box>
  );
};
