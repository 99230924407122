import { useUser } from "../../contexts/UserContext";
import { Profile } from "../Profile";
import { Campaigns } from "../Campaigns";
import { Sequences } from "../Sequences";
import { Chatbot } from "../Chatbot";
import { MainMenu } from "../../components/MainMenu";
import { Box } from "@mui/material";
import styles from "./Bella.module.scss";

const ActivePage = ({ activePage }) => {
  switch (activePage) {
    case "profile":
      return <Profile />;
    case "campaigns":
      return <Campaigns />;
    case "sequences":
      return <Sequences />;
    case "chat bot":
      return <Chatbot />;
    default:
      return "campaigns"; // or some default component or behavior
  }
};

const Bella = () => {
  const { activePage, setActivePage } = useUser();
  return (
    <Box className={styles.body}>
      <MainMenu activePage={activePage} setActivePage={setActivePage} />
      <Box className={styles.activePage}>
        <ActivePage activePage={activePage} />
      </Box>
    </Box>
  );
};

export default Bella;
