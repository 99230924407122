import { NodeProps } from "reactflow";
import { ConnectionRequestNode } from "./Nodes/ConnectionRequestNode";
import { OutreachNode } from "./Nodes/OutreachNode";
import { DefaultNode } from "./Nodes/DefaultNode";
import { ResearchNode } from "./Nodes/ResearchNode";
import { EdgeWithDaysSelector } from "./Edges/EdgeWithDaysSelector";
import {
  Biotech,
  PersonAdd,
  Message,
  Email,
  Quickreply,
  Pageview,
  ThumbUp,
  Bookmark,
} from "@mui/icons-material";

export type Template = {
  name: string;
  content?: string;
  prefix: string;
  needsValue?: boolean;
  placeholder?: string;
  helperText?: string;
};

export const templateTypes = {
  DEFAULT: {
    name: "Default",
    prefix: "DEFAULT",
    helperText:
      "This is the default template for this step. Bella will generate messages based on the insights she has about the prospect and our default set of example messages.",
  },
  EMPTY: {
    name: "Empty",
    prefix: "EMPTY",
    helperText:
      "This template will leave the message blank. It's useful when you want to send Connection Request without a message.",
  },
  EXAMPLE: {
    name: "Example",
    prefix: "EXAMPLE",
    needsValue: true,
    helperText:
      "For this template, you need to enter an example for Bella. She will generate messages based on this example, but personalised for each individual.",
  },
};

export type NodeData = {
  label: string;
  channel: string;
  chosenTemplate?: Template;
  availableTemplates?: Template[];
  autoGenerate?: boolean;
  autoSend?: boolean;
  example?: string;
  productsAndServicesIdxs?: number[];
  caseStudiesIdxs?: number[];
  customInstructions?: string;
  help: string;
  isConnected?: boolean;
  icon: JSX.Element;
};

export type NodeSettingsProps = NodeProps & {
  open: boolean;
  onClose: () => void;
};

export type NodeHeaderProps = NodeData & {
  openSettings: () => void;
};

export const nodeTypes = {
  research: ResearchNode,
  linkedinCR: ConnectionRequestNode,
  linkedinOutreach: OutreachNode,
  emailOutreach: OutreachNode,
  linkedinInMail: OutreachNode,
  viewProfile: DefaultNode,
  likePost: DefaultNode,
  inviteToFollow: DefaultNode,
};

export const edgeTypes = {
  default: EdgeWithDaysSelector,
};

export const nodesInitData = {
  research: {
    icon: <Biotech />,
    label: "Research Prospect",
    channel: "linkedin",
    help: "This step will run research on the prospect and their company by looking at their LinkedIn profiles, activities, jobs, websites, news, etc. Bella will use this information to personalise the messages she sends to the prospect.",
  },
  linkedinCR: {
    icon: <PersonAdd />,
    label: "Connection Request",
    channel: "linkedin",
    messageType: "cr",
    help: "This step will send a connection request to the lead from you LinkedIn Sales Navigator account.",
    chosenTemplate: templateTypes.DEFAULT,
    availableTemplates: [
      templateTypes.DEFAULT,
      templateTypes.EXAMPLE,
      templateTypes.EMPTY,
    ],
    autoGenerate: true,
    autoSend: false,
    productsAndServicesIdxs: [],
    caseStudiesIdxs: [],
    customInstructions: "",
  },
  linkedinOutreach: {
    icon: <Message />,
    label: "Outreach Message",
    channel: "linkedin",
    messageType: "outreach",
    help: "This step will send a message to the lead from your LinkedIn Sales Navigator account when they accepted your connection request.",
    chosenTemplate: templateTypes.DEFAULT,
    availableTemplates: [templateTypes.DEFAULT, templateTypes.EXAMPLE],
    autoGenerate: false,
    autoSend: false,
    productsAndServicesIdxs: [],
    caseStudiesIdxs: [],
    customInstructions: "",
  },
  linkedinInMail: {
    icon: <Quickreply />,
    label: "Outreach InMail",
    help: "This step will send a message to the lead from your LinkedIn Sales Navigator account when they have not accepted your connection request using InMail service.",
    channel: "linkedin",
    messageType: "outreach",
    chosenTemplate: templateTypes.DEFAULT,
    availableTemplates: [templateTypes.DEFAULT, templateTypes.EXAMPLE],
    autoGenerate: false,
    autoSend: false,
    productsAndServicesIdxs: [],
    caseStudiesIdxs: [],
    customInstructions: "",
  },
  emailOutreach: {
    icon: <Email />,
    label: "Email Outreach",
    help: "This step will send an email to the lead if an email address is found.",
    channel: "email",
    messageType: "outreach",
    chosenTemplate: templateTypes.DEFAULT,
    availableTemplates: [templateTypes.DEFAULT, templateTypes.EXAMPLE],
    autoGenerate: false,
    autoSend: false,
    productsAndServicesIdxs: [],
    caseStudiesIdxs: [],
    customInstructions: "",
  },
  viewProfile: {
    icon: <Pageview />,
    label: "View Profile",
    help: "This step will view their LinkedIn profile on your behalf. There is no extra settings for this step.",
    channel: "linkedin",
  },
  likePost: {
    icon: <ThumbUp />,
    label: "Like Latest Post",
    help: "This step will like their latest post on LinkedIn on your behalf. There is no extra settings for this step.",
    channel: "linkedin",
  },
  inviteToFollow: {
    icon: <Bookmark />,
    label: "Invite To Follow",
    help: "This step will invite them to follow you company on LinkedIn on your behalf. There is no extra settings for this step.",
    channel: "linkedin",
  },
};

export const generateNode = (
  type: string,
  idx: number | string,
  nodeData?: NodeData,
  position?: { x: number; y: number },
) => {
  return {
    id: `${type}-${idx}`,
    type: type,
    position: position || { x: 0, y: 0 },
    data: nodeData ? nodeData : nodesInitData[type],
  };
};

export type addNodeAction = {
  id: keyof typeof nodesInitData;
  name: string;
  disabled: boolean;
};
