import { useThread } from "../../contexts/ThreadContext";
import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import { useCallback } from "react";

const toDate = (dt) => {
  return format(new Date(dt * 1000), "dd-MM");
};

export const ThreadStatus = () => {
  const {
    threadData,
    crSent,
    crAccepted,
    emailOutreachSent,
    linkedInOutreachSent,
    replyReceived,
  } = useThread();

  const insightsOk = () => {
    const keys = threadData.insights.map((i) => i.origin.get());
    if (keys.length === 0) return false;
    return (
      keys.includes("lead_linkedin") &&
      keys.includes("company_linkedin") &&
      keys.includes("industry_news")
    );
  };

  const messageStatuses = useCallback(() => {
    const statuses = [];
    if (emailOutreachSent.length > 0) {
      statuses.push({
        color: "primary",
        status: `Email Sent ${toDate(emailOutreachSent[0].sentAt.get())}`,
        sentAt: emailOutreachSent[0].sentAt.get(),
      });
    }
    if (linkedInOutreachSent.length > 0) {
      statuses.push({
        color: "primary",
        status: `LinkedIn Sent ${toDate(linkedInOutreachSent[0].sentAt.get())}`,
        sentAt: linkedInOutreachSent[0].sentAt.get(),
      });
    }
    if (replyReceived.length > 0) {
      statuses.push({
        color: "secondary",
        status: `Reply Received ${toDate(replyReceived[0].createdAt.get())}`,
        repliedAt: replyReceived[0].repliedAt.get(),
      });
    }
    return statuses.sort((a, b) => a.sentAt - b.sentAt);
  }, [emailOutreachSent, linkedInOutreachSent, replyReceived]);

  return insightsOk() ? (
    <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
      {crSent && !crAccepted && (
        <Typography variant="body2" color="primary">
          {`CR Sent ${toDate(crSent.sentAt.get())}`}
        </Typography>
      )}
      {crAccepted && (
        <>
          <Typography variant="body2" color="primary">
            {`CR Sent ${toDate(crAccepted.sentAt.get())}`}
          </Typography>
          <Typography variant="body2" color="secondary">
            {`CR Accepted ${toDate(crAccepted.repliedAt.get())}`}
          </Typography>
        </>
      )}
      {messageStatuses().map((m, i) => (
        <Typography variant="body2" color={m.color} key={i}>
          {m.status}
        </Typography>
      ))}
    </Box>
  ) : (
    <Typography variant="body2" color="secondary">
      Please research the prospect first
    </Typography>
  );
};
