import { Icon } from "@mui/material";

import bellaIcon from "../assets/bellaIcon.png";
import gmailIcon from "../assets/gmail.svg";
import hubSpotLogoIcon from "../assets/HubSpot_Logo.svg";
import hubSpotIcon from "../assets/HubSpot_Icon.svg";
import outlookIcon from "../assets/outlook.svg";
import geminiIcon from "../assets/Gemini_logo.svg";
import openaiIcon from "../assets/OpenAI_Logo.svg";
import anthropicIcon from "../assets/Anthropic_logo.svg";

export const BellaIcon = (props) => {
  return (
    <Icon sx={{ alignItems: "center", width: "fit-content", display: "flex" }}>
      <img src={bellaIcon} style={props} />
    </Icon>
  );
};

export const GmailIcon = (props) => {
  return (
    <Icon
      sx={{
        display: "flex",
        alignContent: "center",
        width: "fit-content",
      }}
    >
      <img src={gmailIcon} {...props} className="GmailIcon" />
    </Icon>
  );
};

export const HubSpotLogoIcon = (props) => {
  return (
    <Icon
      sx={{
        display: "flex",
        alignContent: "center",
        width: "fit-content",
      }}
    >
      <img src={hubSpotLogoIcon} {...props} className="HubSpotLogoIcon" />
    </Icon>
  );
};

export const HubSpotIcon = (props) => {
  return (
    <Icon
      sx={{
        display: "flex",
        alignContent: "center",
        width: "fit-content",
      }}
    >
      <img src={hubSpotIcon} {...props} className="HubSpotIcon" />
    </Icon>
  );
};

export const OutlookIcon = (props) => {
  return (
    <Icon
      sx={{
        display: "flex",
        alignContent: "center",
        width: "fit-content",
      }}
    >
      <img src={outlookIcon} {...props} className="OutlookIcon" />
    </Icon>
  );
};

export const GeminiIcon = (props) => {
  return (
    <Icon
      sx={{
        display: "flex",
        alignContent: "center",
        width: "fit-content",
      }}
    >
      <img src={geminiIcon} {...props} className="GeminiIcon" />
    </Icon>
  );
};

export const OpenAIIcon = (props) => {
  return (
    <Icon
      sx={{
        display: "flex",
        alignContent: "center",
        width: "fit-content",
      }}
    >
      <img src={openaiIcon} {...props} className="OpenAIIcon" />
    </Icon>
  );
};

export const AnthropicIcon = (props) => {
  return (
    <Icon
      sx={{
        display: "flex",
        alignContent: "center",
        width: "fit-content",
      }}
    >
      <img src={anthropicIcon} {...props} className="AnthropicIcon" />
    </Icon>
  );
};
