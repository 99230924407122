import { useMemo, useCallback, useRef } from "react";
import { useCampaign } from "../../contexts/CampaignContext";
import { ThreadCard } from "../../components/ThreadCard";
import { CampaignToolbar } from "./CampaignToolbar";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { Box, Typography, IconButton, Skeleton } from "@mui/material";
import { Thread } from "../../components/types";
import styles from "./Campaigns.module.scss";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useHookstate } from "@hookstate/core";

const InfiniteThreadsLoader = (props: { threads: Thread[] }) => {
  const parentRef = useRef<HTMLDivElement>(null);

  const virtualizer = useVirtualizer({
    count: props.threads.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 350,
    overscan: 5,
  });

  const items = virtualizer.getVirtualItems();

  return (
    <>
      {/* The scrollable element for your list */}
      <Box
        ref={parentRef}
        style={{
          height: "80vh",
          width: "100%",
          contain: "strict",
          overflowY: "auto",
        }}
      >
        {/* The large inner element to hold all of the items */}
        <Box
          style={{
            height: virtualizer.getTotalSize(),
            width: "100%",
            position: "relative",
          }}
        >
          <Box
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              transform: `translateY(${items[0]?.start ?? 0}px)`,
            }}
            pl={2}
            pr={2}
            pt={2}
          >
            {items.map((virtualItem) => (
              <ThreadCard
                key={props.threads[virtualItem.index].pid}
                threadPID={props.threads[virtualItem.index].pid}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};

interface IToolbarSearchProps {
  fullName: string | null;
  companyName: string | null;
  title: string | null;
}

export interface IToolbarProps {
  showHidden: boolean;
  order: string;
  orderBy: string;
  search: IToolbarSearchProps;
}

export const CampaignDisplay = (props: { closeCampaign }) => {
  const { campaignData } = useCampaign();

  const toolbarProps = useHookstate<IToolbarProps>({
    showHidden: false,
    order: "desc",
    orderBy: "updatedAt",
    search: {
      fullName: null,
      companyName: null,
      title: null,
    },
  });

  const orderedThreads = useMemo(() => {
    const threads = campaignData.threads.get();
    if (!threads) return [];
    const sortBy = toolbarProps.orderBy.get();
    return toolbarProps.order.get() === "asc"
      ? threads.toSorted((a, b) => a[sortBy] - b[sortBy])
      : threads.toSorted((a, b) => b[sortBy] - a[sortBy]);
  }, [toolbarProps, campaignData.threads]);

  const visibleThreads = useMemo(() => {
    if (!orderedThreads) return [];
    if (toolbarProps.showHidden.get()) return orderedThreads;
    return orderedThreads.filter((t) => !t.hidden);
  }, [orderedThreads, toolbarProps]);

  const searchedThreads = useMemo(() => {
    if (!visibleThreads) return [];
    const search: IToolbarSearchProps = toolbarProps.search.get();
    return visibleThreads.filter((t) => {
      return (
        t.fullName
          .toLowerCase()
          .includes(search.fullName?.toLowerCase() || "") &&
        t.companyName
          .toLowerCase()
          .includes(search.companyName?.toLowerCase() || "") &&
        t.title.toLowerCase().includes(search.title?.toLowerCase() || "")
      );
    });
  }, [visibleThreads, toolbarProps]);

  const threadsContainer = useCallback(() => {
    return (
      <Box className={styles.threadsContainer}>
        {campaignData.automationRunning.get() ? (
          campaignData.threads.map((t) => {
            return t.automationStatus.get() === "running" ? (
              <ThreadCard
                key={t.pid.get()}
                threadPID={t.pid.get()}
                automationStatus={t.automationStatus}
              />
            ) : null;
          })
        ) : (
          <InfiniteThreadsLoader threads={searchedThreads} />
        )}
      </Box>
    );
  }, [searchedThreads, toolbarProps, campaignData.automationRunning.get()]);

  return campaignData.pid.get() ? (
    <Box className={styles.campaignContainer}>
      <Box className={styles.campaignHeader}>
        <IconButton aria-label="close" onClick={props.closeCampaign}>
          <KeyboardArrowLeft />
        </IconButton>
        <Typography variant="h4">
          <b>Campaign:</b> {campaignData.title.get()}
        </Typography>
      </Box>
      {campaignData && <CampaignToolbar toolbarProps={toolbarProps} />}
      {threadsContainer()}
    </Box>
  ) : (
    <Skeleton
      className={styles.campaignContainer}
      width={"100%"}
      height={118}
    />
  );
};
