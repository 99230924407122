import {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { useHookstate } from "@hookstate/core";
import { callAPI, callExtension } from "../api";
import { useUser } from "./UserContext";
import { Sequence, Campaign } from "../components/types";

export const CampaignContext = createContext(null);

export const CampaignProvider = ({ children, campaignPID }) => {
  const { userData, setUserData } = useUser();
  const campaignData = useHookstate({} as Campaign);
  const [leadsList, setLeadsList] = useState(null);
  const [newLeads, setNewLeads] = useState([]);
  const [newLeadsLoading, setNewLeadsLoading] = useState(false);
  const sequence = useHookstate({} as Sequence); // sequence is read only for CampaignProvider children

  const loadData = useCallback(async () => {
    const res = await callAPI("GET", "campaign", {
      campaignPID: campaignPID,
    });
    campaignData.set(res.campaign);
    sequence.set(res.sequence);
  }, [campaignPID]);

  // Checks Lead List for new leads
  useEffect(() => {
    if (!campaignData.leadsListPID.get()) return;
    const loadListProfiles = async () => {
      // Check if list already exists
      setNewLeadsLoading(true);
      const existingList = await callAPI("GET", "leads", {
        leadListPID: campaignData.leadsListPID.get(),
      });
      setLeadsList(existingList);
      if (!existingList?.lisnId) {
        // Lists without lisnId are not synchronized with LISN. the user has to reload them using leads first
        setNewLeads([]);
        return;
      } else {
        const data = await callExtension("LISN_fetchListProfiles", {
          lisnId: existingList.lisnId,
        });
        setNewLeads(data);
      }
      setNewLeadsLoading(false);
    };
    loadListProfiles();
  }, [campaignData.pid]);

  useEffect(() => {
    if (!campaignPID) return; // needs to load data
    loadData();
  }, [campaignPID, loadData]);

  // !State
  const changeThreadVisibility = (threadPID, hidden) => {
    campaignData.threads
      .find((t) => t.pid.get() === threadPID)
      .hidden.set(hidden);
  };
  const updateCampaign = async (props) => {
    const res = await callAPI("PUT", "campaign", {
      campaignPID: campaignData.pid.get(),
      ...props,
    });
    campaignData.set(res.campaign);
    sequence.set(res.sequence);
    setUserData((prev) => ({
      ...prev,
      campaigns: prev.campaigns.map((c) => (c.pid === res.pid ? res : c)),
    }));
  };

  const postLead = async (leadData) => {
    const res = await callAPI("POST", "/lead", {
      ...leadData,
      leadsListPID: leadsList.pid,
    });
    if (res.success && res.leadsCount) {
      setUserData((prev) => ({
        ...prev,
        totalLeads: res.leadsCount,
      }));
    }
    return userData.leadCredits - userData.totalLeads - 1;
  };

  // !Automation

  const runAutomation = () => {
    // Triggers the automation
    console.log("Initiating automation");
    campaignData.automationRunning.set(true);
    campaignData.threads.set((threads) =>
      threads.map((t) => ({
        ...t,
        automationStatus: !t.hidden ? "queued" : "ignored",
      })),
    );
    nextAutomation();
  };

  const nextAutomation = () => {
    const nextThread = campaignData.threads.find(
      (t) => t.automationStatus.get() === "queued",
    );
    console.log("Next thread", nextThread);
    if (nextThread) {
      nextThread.automationStatus.set("running");
    } else {
      stopAutomation();
    }
  };

  const stopAutomation = () => {
    // Stops the automation
    console.log("Stopping automation");
    campaignData.automationRunning.set(false);
  };

  const automationProgress =
    campaignData?.threads?.length > 0 &&
    campaignData.threads
      .get()
      .filter((t) => ["failed", "finished"].includes(t.automationStatus))
      .length / campaignData.threads.get().filter((t) => !t.hidden).length;

  return (
    <CampaignContext.Provider
      value={{
        campaignData,
        sequence,
        changeThreadVisibility,
        loadData,
        postLead,
        leadsList,
        newLeads,
        setNewLeads,
        newLeadsLoading,
        updateCampaign,
        runAutomation,
        stopAutomation,
        nextAutomation,
        automationProgress,
      }}
    >
      {children}
    </CampaignContext.Provider>
  );
};

export const useCampaign = () => useContext(CampaignContext);
