import { MyInfo } from "./MyInfo";
import { MyCompanyInfo } from "./MyCompanyInfo";
import { ProductsAndServices } from "./ProductsAndServices";
import { Integrations } from "./Integrations";
import { CaseStudies } from "./CaseStudies";
import { Box } from "@mui/material";

const row = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  gap: "10px",
  marginBottom: "20px",
};

export const Profile = () => {
  return (
    <Box>
      <Box sx={row}>
        <MyInfo />
        <MyCompanyInfo />
      </Box>
      <Box sx={row}>
        <ProductsAndServices />
        <CaseStudies />
        <Integrations />
      </Box>
    </Box>
  );
};
