import { useState } from "react";
import { callExtension } from "../../api";
import {
  Box,
  Dialog,
  DialogTitle,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import Papa from "papaparse";
import styles from "./ImportLeadsToNavDialog.module.scss";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export type FileWithBuffer = {
  file: File;
  arrayBuffer: ArrayBuffer;
};

export const ImportLeadsToNavDialog = (props: {
  open: boolean;
  setOpen: (boolean) => void;
}) => {
  const [processing, setProcessing] = useState(null);
  const [messages, setMessages] = useState([]);
  const [errors, setErrors] = useState([]);
  const [records, setRecords] = useState([]);
  const [filename, setFilename] = useState(null);
  const [columnNames, setColumnNames] = useState([]);
  const [linkedInColumn, setLinkedInColumn] = useState(null);
  const [results, setResults] = useState(null);
  const [urls, setUrls] = useState([]);
  const [startTimestamp, setStartTimestamp] = useState(new Date().getTime());
  const [listName, setListName] = useState(null);

  const onClose = () => {
    setProcessing(null);
    setErrors([]);
    setRecords([]);
    setColumnNames([]);
    setLinkedInColumn(null);
    setMessages([]);
    setResults(null);
    setFilename(null);
    setStartTimestamp(null);
    props.setOpen(false);
  };

  const onFileChange = (event) => {
    const file = event.target.files[0];
    setFilename(file.name);
    if (file && file.type === "text/csv") {
      Papa.parse(file, {
        complete: (result) => {
          const headers = result.data[0];
          setColumnNames(
            headers.map((header, index) => ({
              name: header,
              id: index,
            })),
          );
          setRecords(result.data.slice(1));
        },
        header: false, // Set to true if the CSV file contains a header row
        skipEmptyLines: true,
      });
    } else {
      console.log("Please select a CSV file.");
    }
  };

  const readFile = (linkedinCol: string) => {
    if (!linkedinCol) {
      return;
    }
    const result = records
      .map((record) => {
        const linkedin = record[
          columnNames.find((n) => n.name === linkedinCol).id
        ].replace("http:", "https:");
        return {
          linkedIn: linkedin.endsWith("/") ? linkedin.slice(0, -1) : linkedin,
        };
      })
      .filter((record) => record.linkedIn);
    setUrls(result.map((r) => r.linkedIn));
    setMessages([...messages, `Found ${result.length} valid records`]);
  };

  const validateForm = () => {
    const errors = [];
    if (urls.length < 1) {
      errors.push("No valid data found in the file.");
    }
    if (listName === null || listName === "") {
      errors.push("Please provide a correct name for the new list.");
    }
    if (errors.length > 0) {
      setErrors(errors);
      return false;
    }
    return true;
  };

  const fetchLinkedInUrns = async (urls) => {
    const promises = urls.map(async (record) => {
      const res = await callExtension("LI_fetchLinkedInUrl", {
        urlSuffix: record.split(".com/")[1],
      });
      const idx = res.indexOf("fsd_profileCard") + 17;
      return res.html.slice(idx).split(",")[0];
    });

    const urnsFromUrls = await Promise.all(promises);
    return urnsFromUrls;
  };

  const addToSalesNav = async (urnsFromUrls) => {
    const promises = urnsFromUrls.map(async (urn) => {
      try {
        const res = await callExtension("LI_addLeadToLisn", {
          linkedinUrn: urn,
        });
        return res;
      } catch (e) {
        console.log(e);
        return null;
      }
    });
    const results = await Promise.all(promises);
    return results;
  };

  const getLeadLists = async () => {
    const res = await callExtension("LISN_fetchLists");
    return res.map((e) => ({ lisnId: e.lisnId, title: e.title }));
  };

  const getLeadProfiles = async (lisnId) => {
    const res = await callExtension("LISN_fetchListProfiles", {
      lisnId: lisnId,
    });
    return res
      .filter((e) => e.dateAddedToListAt > startTimestamp)
      .map((e) => e.entityUrn);
  };

  const handleProcess = async () => {
    if (!validateForm()) {
      return;
    }
    setProcessing(true);
    setMessages([...messages, "Extracting LinkedIn Profiles..."]);
    const urnsFromUrls = await fetchLinkedInUrns(urls);
    setMessages([...messages, "Adding to Sales Navigator..."]);
    await addToSalesNav(urnsFromUrls);
    setMessages([...messages, "Reading existing lead lists..."]);
    const lists = await getLeadLists();
    const newLeads = await getLeadProfiles(
      lists.find((l) => l.title === "Saved on LinkedIn.com").lisnId,
    );
    setMessages([...messages, "Creating a new lead list..."]);
    const list = await callExtension("LISN_createLeadsList", {
      name: listName,
    });
    setMessages([...messages, "Adding leads to the new list..."]);
    const res = await callExtension("LISN_addLeadsToList", {
      leadUrns: newLeads,
      listId: list.id,
    });
    const successful = res.value.filter((e) => e.status === "SUCCESS");
    setMessages([
      ...messages,
      `Done! Successfully added ${successful.length} out of ${urls.length} leads to Bella.`,
    ]);
  };

  return (
    <Dialog open={props.open} onClose={onClose} fullWidth>
      <DialogTitle>Upload CSV File with LinkedIn urls</DialogTitle>{" "}
      <Typography variant="caption" color={"primary"} className={styles.info}>
        This dialog creates a leads list in your LinkedIn Sales Nav from the
        file containing LinkedIn urls. The file must be CSV and it must contain
        full LinkedIn urls in one of the columns. After uploading the file,
        please, make sure to select the right column name.
      </Typography>
      <Box>
        {errors.length > 0 &&
          errors.map((error, idx) => (
            <Typography
              key={idx}
              variant="caption"
              color={"error"}
              className={styles.error}
            >
              {error}
            </Typography>
          ))}
        <Box display="flex" flexDirection="row" p={1} gap={2}>
          <TextField
            value={listName || ""}
            onChange={(e) => setListName(e.target.value)}
            placeholder="Name of the list"
            size="small"
            disabled={processing}
          />
          <Button
            component="label"
            variant="contained"
            startIcon={<CloudUpload />}
            disabled={processing}
          >
            Upload file
            <VisuallyHiddenInput
              type="file"
              id="fileInput"
              accept=".csv"
              style={{ display: "none" }}
              onChange={onFileChange}
              required={true}
            />
          </Button>
          <TextField
            variant="standard"
            value={filename || ""}
            read-only="true"
            disabled={true}
            size="small"
          />
        </Box>
        {columnNames.length >= 2 && (
          <div className={styles.columnSelectors}>
            <FormControl
              fullWidth
              className={styles.columnSelector}
              size="small"
            >
              <InputLabel id="select-linkedin-column-label">
                LinkedIn Urls Column
              </InputLabel>
              <Select
                labelId="select-linkedin-column-label"
                id="select-linkedin-column"
                label="LinkedIn Urls Column"
                value={linkedInColumn || ""}
                onChange={(e) => {
                  setLinkedInColumn(e.target.value);
                  readFile(e.target.value);
                }}
                fullWidth
                disabled={processing}
              >
                {columnNames.map((s, idx) => {
                  return (
                    <MenuItem key={idx} value={s.name}>
                      {s.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        )}
        {messages && (
          <div>
            <Box display="flex" flexDirection="column">
              {messages.map((m, idx) => (
                <Typography
                  key={idx}
                  variant="caption"
                  color={"success"}
                  className={styles.foundInfo}
                >
                  {m}
                </Typography>
              ))}
            </Box>
            <div className={styles.processButton}>
              {processing ? (
                <div>
                  <CircularProgress size="small" />
                  Processing...
                </div>
              ) : (
                <Button
                  onClick={handleProcess}
                  variant="contained"
                  disabled={
                    urls.length < 1 ||
                    listName === null ||
                    listName === "" ||
                    processing
                  }
                >
                  Process
                </Button>
              )}
            </div>
            {results && results.length > 0 && (
              <div className={styles.resultsContainer}>
                Successfully updated {results.length} emails for:
                {results.map((result) => (
                  <div key={result.pid}>
                    <b>
                      {result.firstName} {result.lastName}
                    </b>
                    ({result.email})
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </Box>
    </Dialog>
  );
};
