import { useState, useEffect } from "react";
import { useUser } from "../../contexts/UserContext";
import { useReactFlow } from "reactflow";
import {
  Drawer,
  Typography,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Skeleton,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Checkbox,
  ListItemIcon,
  Divider,
  FormGroup,
  FormControlLabel,
  IconButton,
} from "@mui/material";

import {
  Delete,
  DeleteForever,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";

import { NodeSettingsProps, Template, NodeData } from "./types";
import { ChannelIcon } from "./components";
import styles from "./Sequences.module.scss";

export const NodeSettings = (props: NodeSettingsProps) => {
  const { getNode, setNodes, deleteElements } = useReactFlow();

  const { userData } = useUser();

  const [nodeData, setNodeData] = useState<NodeData>(null);

  const [expandPnS, setExpandPnS] = useState<boolean>(false);
  const [expandCS, setExpandCS] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const closeAndSave = () => {
    setNodes((prev) =>
      prev.map((node) =>
        node.id === props.id ? { ...node, data: nodeData } : node,
      ),
    );
    props.onClose();
  };

  useEffect(() => {
    if (props.id) {
      setNodeData(getNode(props.id)?.data);
    }
  }, [props.id, getNode]);

  const updateDataProp = (key, value) => {
    setNodeData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const hasCustomInstructions = [
    "linkedinCR",
    "linkedinOutreach",
    "emailOutreach",
    "linkedinInMail",
  ].includes(props.type);

  const isChecked = (idx: number, idxs: number[] | null) => {
    if (!idxs) return true;
    if (idxs.length === 0) return true;
    return idxs.includes(idx);
  };

  const toggleProductsAndServicesIdx = (idx: number) => {
    const newValue = nodeData.productsAndServicesIdxs?.includes(idx)
      ? nodeData.productsAndServicesIdxs?.filter((i) => i !== idx)
      : [...(nodeData.productsAndServicesIdxs || []), idx];
    updateDataProp("productsAndServicesIdxs", newValue);
  };

  const toggleCaseStudiesIdx = (idx: number) => {
    const newValue = nodeData.caseStudiesIdxs?.includes(idx)
      ? nodeData.caseStudiesIdxs?.filter((i) => i !== idx)
      : [...(nodeData.caseStudiesIdxs || []), idx];
    updateDataProp("caseStudiesIdxs", newValue);
  };

  const deleteNode = () => {
    if (!confirmDelete) {
      setConfirmDelete(true);
      setTimeout(() => setConfirmDelete(false), 3000);
    } else {
      deleteElements({ nodes: [getNode(props.id)] });
      setConfirmDelete(false);
      props.onClose();
    }
  };

  return (
    <Drawer open={props.open} onClose={closeAndSave} anchor="right">
      <Box
        role="presentation"
        width={600}
        p={1}
        display="flex"
        flexDirection="column"
        gap={2}
      >
        {nodeData ? (
          <Box display="flex" flexDirection="column" gap={3}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              gap={1}
            >
              <Box display="flex" flexDirection="row" gap={1}>
                <ChannelIcon channel={nodeData.channel} />
                {nodeData.icon}
                <Typography variant="h4">{nodeData.label}</Typography>
              </Box>
              <IconButton onClick={deleteNode}>
                {confirmDelete ? <DeleteForever color="error" /> : <Delete />}
              </IconButton>
            </Box>
            <Box className={styles.helpNote}>
              <Typography variant="caption">{nodeData.help}</Typography>
            </Box>
            {nodeData.availableTemplates && (
              <FormControl fullWidth>
                <InputLabel id="template-label">Select Template</InputLabel>
                <Select
                  labelId="template-label"
                  id="template"
                  label="Select Template"
                  value={nodeData.chosenTemplate.prefix}
                  onChange={(e) =>
                    updateDataProp(
                      "chosenTemplate",
                      nodeData.availableTemplates.find(
                        (t) => t.prefix === e.target.value,
                      ),
                    )
                  }
                  required
                  size="small"
                >
                  {nodeData.availableTemplates.map(
                    (l: Template, idx: number) => (
                      <MenuItem key={idx} value={l.prefix}>
                        {l.name}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
            )}
            {nodeData.chosenTemplate?.helperText && (
              <Box className={styles.helpNote}>
                <Typography variant="caption">
                  {nodeData.chosenTemplate.helperText}
                </Typography>
              </Box>
            )}
            {nodeData.chosenTemplate?.needsValue && (
              <TextField
                id={`${nodeData.chosenTemplate.prefix}-input`}
                label={nodeData.chosenTemplate.name}
                value={nodeData.chosenTemplate.content || ""}
                size="small"
                multiline
                minRows={4}
                onChange={(e) =>
                  updateDataProp("chosenTemplate", {
                    ...nodeData.chosenTemplate,
                    content: e.target.value,
                  })
                }
              />
            )}
            <Divider />
            {hasCustomInstructions &&
              nodeData.chosenTemplate.prefix !== "EMPTY" && (
                <Box display="flex" flexDirection="column" width="100%" p={1}>
                  <Typography variant="subtitle2">Automation</Typography>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={nodeData.autoGenerate}
                          onChange={(e) => {
                            updateDataProp("autoGenerate", e.target.checked);
                            if (!e.target.checked) {
                              updateDataProp("autoSend", false);
                            }
                          }}
                        />
                      }
                      label="Auto-Generate"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={nodeData.autoSend}
                          onChange={(e) =>
                            updateDataProp("autoSend", e.target.checked)
                          }
                          disabled={!nodeData.autoGenerate}
                        />
                      }
                      label="Auto-Send"
                    />
                  </FormGroup>
                  <Box flex={1}>
                    <Box id="select-products-and-services">
                      <List
                        sx={{
                          width: "100%",
                          bgcolor: "background.paper",
                        }}
                      >
                        <ListItemButton
                          onClick={() => setExpandPnS(!expandPnS)}
                        >
                          <ListItemText secondary="Specify Product and Services" />
                          {expandPnS ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={expandPnS} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding dense={true}>
                            {userData?.services.map((s, idx) => (
                              <ListItem disablePadding key={`service-${idx}`}>
                                <ListItemIcon>
                                  <Checkbox
                                    edge="start"
                                    checked={isChecked(
                                      idx,
                                      nodeData.productsAndServicesIdxs,
                                    )}
                                    tabIndex={-1}
                                    disableRipple
                                    onClick={() =>
                                      toggleProductsAndServicesIdx(idx)
                                    }
                                    inputProps={{
                                      "aria-labelledby": `check-list-label-${idx}`,
                                    }}
                                  />
                                </ListItemIcon>
                                <ListItemText secondary={s} />
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      </List>
                    </Box>
                    <Box id="select-case-studies" flex={1}>
                      <List
                        sx={{
                          width: "100%",
                          bgcolor: "background.paper",
                        }}
                      >
                        <ListItemButton onClick={() => setExpandCS(!expandCS)}>
                          <ListItemText secondary="Specify Case Studies" />
                          {expandCS ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={expandCS} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding dense={true}>
                            {userData?.caseStudies.map((s, idx) => (
                              <ListItem
                                disablePadding
                                key={`case-study-${idx}`}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    edge="start"
                                    checked={isChecked(
                                      idx,
                                      nodeData.caseStudiesIdxs,
                                    )}
                                    tabIndex={-1}
                                    disableRipple
                                    onClick={() => toggleCaseStudiesIdx(idx)}
                                    inputProps={{
                                      "aria-labelledby": `check-list-label-${idx}`,
                                    }}
                                  />
                                </ListItemIcon>
                                <ListItemText secondary={s} />
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      </List>
                    </Box>
                  </Box>
                  <Box flex={1} p={1}>
                    <TextField
                      id="custom-instructions"
                      multiline
                      placeholder="Write custom instructions for Bella..."
                      rows={4}
                      value={nodeData.customInstructions || ""}
                      onChange={(e) =>
                        updateDataProp("customInstructions", e.target.value)
                      }
                      fullWidth
                      sx={{
                        textarea: {
                          lineHeight: 1.1,
                          fontSize: "12px",
                          overflow: "auto",
                        },
                      }}
                    />
                  </Box>
                </Box>
              )}
          </Box>
        ) : (
          <Skeleton variant="rectangular" height={200} />
        )}
      </Box>
    </Drawer>
  );
};
