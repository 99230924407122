import { useState } from "react";
import { useUser } from "../contexts/UserContext";
import { logoutAPI, callAPI } from "../api";

import {
  alpha,
  styled,
  Menu,
  MenuItem,
  MenuProps,
  Box,
  IconButton,
  Avatar,
  AvatarGroup,
  Tooltip,
  Typography,
  InputBase,
  Divider,
} from "@mui/material";

import {
  AccountBox,
  Campaign,
  SupportAgent,
  Logout,
  Psychology,
  Login,
  KeyboardArrowDown,
} from "@mui/icons-material";

const StyledMenu = styled((props: MenuProps) => (
  <Box>
    <Menu
      onKeyDown={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  </Box>
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const LogInAs = () => {
  const [showInput, setShowInput] = useState(false);
  const { fetchProfile } = useUser();
  const [email, setEmail] = useState(null);
  const [loggedInAs, setLoggedInAs] = useState(false);

  const handleLoginAs = async () => {
    const res = await callAPI("GET", "/loginas", { email: email });
    if (!res.success) {
      await fetchProfile();
      setLoggedInAs(true);
    }
  };

  return (
    <>
      <IconButton
        key="logInAsUsrMenuItem"
        onClick={() => {
          setShowInput(!showInput);
        }}
      >
        <Psychology />
      </IconButton>
      {showInput && (
        <Box display="flex" flexDirection="row" padding="5px">
          <Divider />
          <InputBase
            type="text"
            placeholder="Email"
            size="small"
            value={email || ""}
            color="secondary"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton
            color={loggedInAs ? "success" : "secondary"}
            size="small"
            onClick={handleLoginAs}
          >
            <Login />
          </IconButton>
        </Box>
      )}
    </>
  );
};

export const UserMenu = (props: { logout }) => {
  const { userData, setUserData, setActivePage } = useUser();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handlePageSelection = (page: string) => {
    setActivePage(page);
    handleCloseUserMenu();
  };

  const logout = async () => {
    await logoutAPI();
    props.logout();
    setUserData(null);
    setAnchorElUser(null);
  };
  return (
    <Box sx={{ flexGrow: 0, display: "flex", flexDirection: "row" }}>
      <Tooltip title="User Menu">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <AvatarGroup>
            <Avatar
              alt={userData?.firstName + " " + userData?.lastName}
              src={userData.linkedin?.picture}
            />
            <Avatar
              alt={userData?.company?.name}
              src={userData.company?.linkedin?.picture}
            />
            <Box alignItems="center" display="flex" color="white">
              <KeyboardArrowDown />
            </Box>
          </AvatarGroup>
        </IconButton>
      </Tooltip>

      <StyledMenu
        sx={{ mt: "45px", zIndex: 2000 }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem
          key="profileUsrMenuItem"
          onClick={() => handlePageSelection("profile")}
        >
          <AccountBox />
          <Typography textAlign="center">Profile</Typography>
        </MenuItem>
        <MenuItem
          key="campaignsUsrMenuItem"
          onClick={() => handlePageSelection("campaigns")}
        >
          <Campaign />
          <Typography textAlign="center">Campaigns</Typography>
        </MenuItem>
        <MenuItem
          key="supportUsrMenuItem"
          onClick={() => window.open("https://discord.gg/X5FFdztKZE", "_blank")} // Navigate here
        >
          <SupportAgent />
          <Typography textAlign="center">Support</Typography>
        </MenuItem>
        <MenuItem key="logoutUsrMenuItem" onClick={() => logout()}>
          <Logout />
          <Typography textAlign="center">Log Out</Typography>
        </MenuItem>
      </StyledMenu>
      {userData?.isAdmin && <LogInAs />}
    </Box>
  );
};
