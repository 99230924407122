import { useState, useEffect } from "react";
import { callAPI } from "../../api";
import { Helper } from "../../components/Helper";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  IconButton,
  Modal,
  Switch,
  Card,
  CardContent,
  ButtonGroup,
} from "@mui/material";
import {
  HelpOutline,
  Save,
  Add,
  HourglassBottom,
  Delete,
  DeleteForever,
  SwapHoriz,
  Edit,
} from "@mui/icons-material";
import styles from "./Chatbot.module.scss";

const modalStyle = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 3,
  display: "flex",
  flexDirection: "column",
  gap: 1,
};

interface IMessage {
  idx: number;
  from: "me" | "prospect";
  text: string;
}

interface IFAQ {
  question: string;
  answer: string;
}

interface IChatbotConfig {
  pid: string;
  chatbotOn: boolean;
  calendarLink: string;
  exampleConversations: IMessage[][];
  customInstructions: string;
  faqs: IFAQ[];
}

const NewFAQModal = ({
  open,
  setOpen,
  onSave,
  editFAQ = { question: "", answer: "" },
}) => {
  const [faq, setFAQ] = useState<IFAQ>(editFAQ);

  useEffect(() => {
    if (!open) {
      setFAQ(editFAQ);
    }
  }, [open]);

  const save = async () => {
    await onSave(faq);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="add-faq-modal-title"
    >
      <Box sx={modalStyle}>
        <Typography id="add-faq-modal-title" variant="h6">
          Add a Frequently Asked Question and Answer
        </Typography>
        <TextField
          id="question"
          sx={{ mt: 2 }}
          label="Question"
          variant="outlined"
          multiline
          minRows={2}
          fullWidth
          value={faq.question}
          onChange={(e) => setFAQ({ ...faq, question: e.target.value })}
        />
        <TextField
          id="answer"
          sx={{ mt: 2 }}
          label="Answer"
          variant="outlined"
          multiline
          minRows={2}
          fullWidth
          value={faq.answer}
          onChange={(e) => setFAQ({ ...faq, answer: e.target.value })}
        />
        <Box display="flex" justifyContent="end">
          <Button
            color="primary"
            onClick={save}
            disabled={!faq.question || !faq.answer}
            variant="contained"
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const NewConversationModal = ({
  open,
  setOpen,
  onSave,
  editConversation = [],
}) => {
  const [conversation, setConversation] =
    useState<IMessage[]>(editConversation);
  const [loading, setLoading] = useState(false);

  const lastMessage = conversation[conversation.length - 1];

  useEffect(() => {
    if (!open) {
      setConversation(editConversation);
    }
  }, [open, editConversation]);

  const handleSave = async () => {
    setLoading(true);
    await onSave(conversation);
    setOpen(false);
    setLoading(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="add-conversation-modal-title"
    >
      <Box sx={modalStyle}>
        <Typography id="add-conversation-modal-title" variant="h6">
          Add an Example Conversation
        </Typography>
        <Box display="flex" flexDirection="column" gap={1}>
          {conversation.map((c: IMessage) => (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent={c.from === "me" ? "end" : "start"}
              key={c.idx}
            >
              <Box
                bgcolor={c.from === "me" ? "#5457FC33" : "#1C602133"}
                color="white"
                p={1}
                borderRadius={5}
                width="70%"
              >
                <Box
                  color="black"
                  display="flex"
                  justifyContent={c.from === "me" ? "flex-end" : "flex-start"}
                >
                  {c.from === "me" ? "You" : "Lead"}
                </Box>
                <TextField
                  id={`message-${c.idx}`}
                  variant="standard"
                  multiline
                  fullWidth
                  value={c.text}
                  onChange={(e) =>
                    setConversation(
                      conversation.map((conv) =>
                        conv.idx === c.idx
                          ? { ...conv, text: e.target.value }
                          : conv,
                      ),
                    )
                  }
                />
              </Box>
            </Box>
          ))}
        </Box>
        <Box display="flex" justifyContent="start">
          <IconButton
            color="success"
            onClick={() =>
              setConversation([
                ...conversation,
                {
                  idx: conversation.length,
                  from:
                    !lastMessage || lastMessage.from === "me"
                      ? "prospect"
                      : "me",
                  text: "",
                },
              ])
            }
            disabled={conversation.length === 6}
          >
            <Add />
          </IconButton>
          {conversation.length > 0 && (
            <IconButton
              color="secondary"
              onClick={() =>
                setConversation(
                  conversation.map((c: IMessage) => ({
                    ...c,
                    from: c.from === "me" ? "prospect" : "me",
                  })),
                )
              }
              disabled={conversation.length === 6}
            >
              <SwapHoriz />
            </IconButton>
          )}
          {conversation.length > 0 && (
            <IconButton
              color="warning"
              onClick={() =>
                setConversation(conversation.slice(0, conversation.length - 1))
              }
              disabled={conversation.length === 0}
            >
              <Delete />
            </IconButton>
          )}
        </Box>
        <Box display="flex" justifyContent="end">
          <Button
            color="primary"
            onClick={handleSave}
            //disable if any string in any of the conversation objects is empty
            disabled={
              conversation.some((c) => !c.text) ||
              conversation.length < 2 ||
              loading
            }
            variant="contained"
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const FAQItem = ({ question, answer, onDelete, onEdit }) => {
  const [confirm, setConfirm] = useState(false);
  const [showEditFAQModal, setShowEditFAQModal] = useState(false);

  const handleDelete = () => {
    if (confirm) {
      onDelete();
    } else {
      setTimeout(() => setConfirm(false), 3000);
    }
  };

  return (
    <Box p={1} position="relative">
      {showEditFAQModal && (
        <NewFAQModal
          open={showEditFAQModal}
          setOpen={setShowEditFAQModal}
          onSave={onEdit}
          editFAQ={{ question: question, answer: answer }}
        />
      )}
      <Typography variant="body1" color="primary" width="90%">
        {question}
      </Typography>
      <Typography variant="body2" textAlign="right" color="secondary">
        {answer}
      </Typography>
      <Box position="absolute" right={5} top={0}>
        <IconButton
          onClick={handleDelete}
          color={confirm ? "error" : "warning"}
        >
          {confirm ? <DeleteForever /> : <Delete />}
        </IconButton>
        <IconButton onClick={() => setShowEditFAQModal(true)}>
          <Edit />
        </IconButton>
      </Box>
    </Box>
  );
};

const ConversationAccordion = ({ items, onDelete, onEdit }) => {
  const [expandedIndex, setExpandedIndex] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const [showEditConversationModal, setShowEditConversationModal] =
    useState(false);

  const handleMouseEnter = (index) => {
    setExpandedIndex(index);
  };

  const handleMouseLeave = () => {
    setExpandedIndex(0);
  };

  const handleDelete = () => {
    if (confirm) {
      onDelete();
    } else {
      setTimeout(() => setConfirm(false), 3000);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
      p={1}
    >
      {showEditConversationModal && (
        <NewConversationModal
          open={showEditConversationModal}
          setOpen={setShowEditConversationModal}
          onSave={onEdit}
          editConversation={items}
        />
      )}
      <Box sx={{ display: "flex", flexDirection: "row", width: "90%" }}>
        {items.map((item: IMessage) => (
          <Card
            key={item.idx}
            onMouseEnter={() => handleMouseEnter(item.idx)}
            onMouseLeave={handleMouseLeave}
            sx={{
              transition: "width 1s",
              width: expandedIndex === item.idx ? "100%" : 50,
              height: "80px",
              overflow: "hidden",
              bgcolor: item.from === "me" ? "#5457FC33" : "#1C602133",
            }}
          >
            <CardContent>
              {expandedIndex === item.idx && (
                <Typography variant="body2">
                  {item.text.length > 300
                    ? item.text.slice(0, 300) + "..."
                    : item.text}
                </Typography>
              )}
            </CardContent>
          </Card>
        ))}
      </Box>
      <Box display="flex" alignItems="center">
        <ButtonGroup variant="outlined" aria-label="Basic button group">
          <IconButton
            onClick={handleDelete}
            color={confirm ? "error" : "warning"}
          >
            {confirm ? <DeleteForever /> : <Delete />}
          </IconButton>
          <IconButton onClick={() => setShowEditConversationModal(true)}>
            <Edit />
          </IconButton>
        </ButtonGroup>
      </Box>
    </Box>
  );
};

export const Chatbot = () => {
  const [showNewFAQModal, setShowNewFAQModal] = useState(false);
  const [showNewConversationModal, setShowNewConversationModal] =
    useState(false);

  const [chatbotConfig, setChatbotConfig] = useState({} as IChatbotConfig);
  const [calendarLink, setCalendarLink] = useState("");
  const [calendarLinkLoading, setCalendarLinkLoading] = useState(false);
  const [customInstructions, setCustomInstructions] = useState("");
  const [customInstructionsLoading, setCustomInstructionsLoading] =
    useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const res = await callAPI("GET", "chatbot_config");
      setChatbotConfig(res);
      setCalendarLink(res.calendarLink || "");
      setCustomInstructions(res.customInstructions || "");
    };
    fetchData();
  }, []);

  const setChatbotOn = async (on: boolean) => {
    const res = await callAPI("PUT", "chatbot_config", { chatbotOn: on });
    setChatbotConfig(res);
  };

  const verifyLink = () => {
    // Any link that starts with https:// and may have any endpoint
    const regex = new RegExp("^(http|https)://[^\\s/$.?#].[^\\s]*$", "g");
    return regex.test(calendarLink);
  };

  const saveCalendarLink = async () => {
    console.log(verifyLink());
    if (verifyLink()) {
      setCalendarLinkLoading(true);
      const res = await callAPI("PUT", "chatbot_config", {
        calendarLink: calendarLink,
      });
      setChatbotConfig(res);
      setCalendarLinkLoading(false);
    }
  };

  const saveCustomInstructions = async () => {
    setCustomInstructionsLoading(true);
    const res = await callAPI("PUT", "chatbot_config", {
      customInstructions: customInstructions,
    });
    setChatbotConfig(res);
    setCustomInstructionsLoading(false);
  };

  const addFAQ = async (faq: IFAQ) => {
    const res = await callAPI("PUT", "chatbot_config", {
      faqs: [...(chatbotConfig.faqs || []), faq],
    });
    setChatbotConfig(res);
  };

  const editFAQ = async (faq: IFAQ, idx: number) => {
    const res = await callAPI("PUT", "chatbot_config", {
      faqs: chatbotConfig.faqs.map((f, i) => (i === idx ? faq : f)),
    });
    setChatbotConfig(res);
  };

  const deleteFaq = async (question: string) => {
    const res = await callAPI("PUT", "chatbot_config", {
      faqs: chatbotConfig.faqs.filter((faq) => faq.question !== question),
    });
    setChatbotConfig(res);
  };

  const addConversation = async (conversation: IMessage[]) => {
    const res = await callAPI("PUT", "chatbot_config", {
      exampleConversations: [
        ...(chatbotConfig.exampleConversations || []),
        conversation,
      ],
    });
    setChatbotConfig(res);
  };

  const editConversation = async (conversation: IMessage[], idx: number) => {
    const res = await callAPI("PUT", "chatbot_config", {
      exampleConversations: chatbotConfig.exampleConversations.map((conv, i) =>
        i === idx ? conversation : conv,
      ),
    });
    setChatbotConfig(res);
  };

  const deleteConversation = async (idx: number) => {
    const res = await callAPI("PUT", "chatbot_config", {
      exampleConversations: chatbotConfig.exampleConversations.filter(
        (conv, i) => i !== idx,
      ),
    });
    setChatbotConfig(res);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "calc(100vh - 150px)",
      }}
    >
      <Box display="flex" flexDirection="row" alignItems="center" p={1}>
        <Typography variant="h3">Bella Chat Bot</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6} md={4}>
          <Box className={styles.raisedTile}>
            <Typography variant="subtitle1">Main Settings</Typography>
            <Typography variant="body2" p={1} color="primary">
              Bella Chat Bot is a AI Chatbot for LinkedIn that can handle reply
              messages after the outreach sequence is completed.
            </Typography>
            <Typography variant="body2" p={1} color="primary">
              When one of your Bella prospects replies to your outreach message,
              the outreach sequence stops and is no longer required to generate
              outreach messages. The Chat Bot comes in handy when you receive a
              significant amount of replies and you want to automate the process
              of replying to them, at least for the first few interactions.
            </Typography>
            <Typography variant="body2" color="darkorange" p={1}>
              Bella Chat Bot is still in early development and has a limited set
              of features. It also requires some experimenting on your side to
              adjust the settings on this page to meet your desired results.
            </Typography>
            <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
              <Helper title="Turns the Chat Bot on and off">
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  fontSize={10}
                  fontWeight="bold"
                  color={chatbotConfig.chatbotOn && "red"}
                >
                  {chatbotConfig.chatbotOn ? "On" : "Off"}
                  <Switch
                    checked={chatbotConfig?.chatbotOn || false}
                    onChange={(e) => setChatbotOn(e.target.checked)}
                  />
                </Box>
              </Helper>
              <Box position="relative" width="100%">
                <Helper
                  title={
                    calendarLink.length > 0 && !verifyLink()
                      ? "Incorrect Link"
                      : "Enter your calendar link here. It will be sent to leads when they ask for a meeting."
                  }
                  placement={"top-start"}
                >
                  <TextField
                    label="Your Calendar Link"
                    variant="outlined"
                    fullWidth
                    value={calendarLink || ""}
                    onChange={(e) => setCalendarLink(e.target.value)}
                    size="small"
                    error={calendarLink.length > 0 && !verifyLink()}
                  />
                </Helper>
                {chatbotConfig.calendarLink !== calendarLink && (
                  <IconButton
                    color="success"
                    sx={{ position: "absolute", right: 5, top: 0 }}
                    disabled={
                      !verifyLink() ||
                      calendarLink.length === 0 ||
                      calendarLinkLoading
                    }
                    onClick={saveCalendarLink}
                  >
                    {calendarLinkLoading ? <HourglassBottom /> : <Save />}
                  </IconButton>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} md={8}>
          <Box className={styles.raisedTile} height="100%">
            <Typography variant="subtitle1">Custom Instructions</Typography>
            <Box p={1} sx={{ position: "relative" }}>
              <TextField
                multiline
                fullWidth
                placeholder="Enter your custom instructions to the Bella Chat Bot here..."
                variant="outlined"
                size="small"
                rows={9}
                value={customInstructions}
                onChange={(e) => setCustomInstructions(e.target.value)}
              />
              <Box sx={{ position: "absolute", right: 10, top: 10 }}>
                <Helper
                  title="These instructions is the key for good replies. They should be guidelines to the Chat Bot based on the conversation flow as well as define its communication style and tone."
                  placement="left-end"
                >
                  <IconButton color="primary">
                    <HelpOutline />
                  </IconButton>
                </Helper>
              </Box>
              <Box sx={{ position: "absolute", right: 10, bottom: 10 }}>
                <IconButton
                  color="primary"
                  onClick={saveCustomInstructions}
                  disabled={customInstructionsLoading}
                >
                  {customInstructionsLoading ? <HourglassBottom /> : <Save />}
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6} md={8}>
          <Box className={styles.raisedTile} height="100%">
            {showNewConversationModal && (
              <NewConversationModal
                open={showNewConversationModal}
                setOpen={setShowNewConversationModal}
                onSave={addConversation}
              />
            )}
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="subtitle1">Example Conversations</Typography>
              <IconButton
                color="primary"
                onClick={() => setShowNewConversationModal(true)}
              >
                <Add />
              </IconButton>
            </Box>
            {chatbotConfig.exampleConversations?.map((items, index) => (
              <ConversationAccordion
                key={index}
                items={items}
                onDelete={() => deleteConversation(index)}
                onEdit={(conv) => editConversation(conv, index)}
              />
            ))}
          </Box>
        </Grid>
        <Grid item xs={6} md={4}>
          <Box className={styles.raisedTile} height="100%">
            <NewFAQModal
              open={showNewFAQModal}
              setOpen={setShowNewFAQModal}
              onSave={addFAQ}
            />
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="subtitle1">FAQs</Typography>
              <IconButton
                color="primary"
                onClick={() => setShowNewFAQModal(true)}
              >
                <Add />
              </IconButton>
            </Box>
            <Box>
              {chatbotConfig.faqs?.map((faq, idx) => (
                <FAQItem
                  key={idx}
                  {...faq}
                  onDelete={() => deleteFaq(faq.question)}
                  onEdit={(faq) => editFAQ(faq, idx)}
                />
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
