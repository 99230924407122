import { useCallback, useState, forwardRef } from "react";
import { useThread } from "../../contexts/ThreadContext";
import {
  Box,
  Switch,
  CircularProgress,
  Button,
  ButtonGroup,
  FormGroup,
  FormControlLabel,
  Tooltip,
} from "@mui/material";

import { Biotech, AutoFixHigh, Send } from "@mui/icons-material";

import { MessagesNav } from "./MessagesNav";
import { AiAssistantDialog } from "./AiAssistant";
import styles from "./ThreadCard.module.scss";

export const Footer = () => {
  const {
    threadData,
    lead,
    currentMessage,
    generateMessage,
    sendCR,
    crSent,
    sendMessage,
    sendEmail,
    research,
    currentSteps,
  } = useThread();

  const [loading, setLoading] = useState(false);

  const [showAI, setShowAI] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ActionButton = forwardRef((props: any, ref) => {
    const [btnLabel, setLabel] = useState<string>(props.label);

    const confirm = () => {
      setLabel("Confirm");
      setTimeout(() => {
        setLabel(btnLabel);
      }, 2000);
    };

    const handleOnClick = async () => {
      if (btnLabel.startsWith("Send")) {
        confirm();
      } else {
        setLoading(true);
        await props.onClick();
        setLoading(false);
      }
    };

    const actButton = useCallback(
      () => (
        <Button
          color={btnLabel === "Confirm" ? "warning" : "primary"}
          size="small"
          id="threadActionButton"
          {...props}
          onClick={handleOnClick} // Important to keep it under {...props} to override onClick call with handleOnClick
        >
          {btnLabel}
        </Button>
      ),
      [btnLabel, props],
    );

    if (props.tooltip) {
      return (
        <Tooltip title={props.tooltip} ref={ref}>
          <span>{actButton()}</span>
        </Tooltip>
      );
    }
    return actButton();
  });

  const researchStep = currentSteps.find((s) => s.nodeType === "research");
  const crStep = currentSteps.find((s) => s.messageType === "cr");
  const linkedinOutreachStep = currentSteps.find(
    (s) => s.messageType === "outreach" && s.channel === "linkedin",
  );
  const emailOutreachStep = currentSteps.find(
    (s) => s.messageType === "outreach" && s.channel === "email",
  );

  const renderLinkedInActionButtons = () => {
    if (researchStep) {
      return (
        <ActionButton
          label="Research"
          variant="outlined"
          startIcon={<Biotech />}
          onClick={() => research(researchStep.pid)}
          key={"research-action"}
        />
      );
    }
    const actions = [];
    if (crStep && !crSent && lead.degree > 1) {
      actions.push(
        <ActionButton
          label="Generate CR"
          variant="outlined"
          startIcon={<AutoFixHigh />}
          onClick={() => generateMessage("cr", "linkedin", crStep.pid)}
          key={"generate-cr-action"}
        />,
      );
      if (
        currentMessage?.type?.get() === "cr" &&
        currentMessage?.status?.get() === "created"
      ) {
        actions.push(
          <ActionButton
            label="Send CR"
            variant="contained"
            endIcon={<Send />}
            onClick={() => sendCR()}
            key={"send-cr-action"}
          />,
        );
      }
    }
    if (linkedinOutreachStep) {
      actions.push(
        <ActionButton
          label={`Generate LinkedIn ${lead.degree > 1 ? "InMail" : "Outreach"}`}
          variant="outlined"
          startIcon={<AutoFixHigh />}
          onClick={() =>
            generateMessage("outreach", "linkedin", linkedinOutreachStep.pid)
          }
          key={"generate-linkedin-outreach-action"}
        />,
      );
      if (
        currentMessage?.type?.get() === "outreach" &&
        currentMessage?.status?.get() === "created" &&
        currentMessage?.channel?.get() === "linkedin"
      ) {
        actions.push(
          <ActionButton
            label={`Send LinkedIn ${lead.degree > 1 ? "InMail" : "Outreach"}`}
            variant="contained"
            endIcon={<Send />}
            onClick={() => sendMessage(linkedinOutreachStep.pid)}
            key={"send-linkedin-outreach-action"}
          />,
        );
      }
    }
    return <ButtonGroup>{actions}</ButtonGroup>;
  };

  const renderEmailActionButtons = () => {
    const actions = [];
    if (emailOutreachStep) {
      actions.push(
        <ActionButton
          label="Generate Email Outreach"
          variant="outlined"
          startIcon={<AutoFixHigh />}
          onClick={() =>
            generateMessage("outreach", "email", emailOutreachStep.pid)
          }
          key={"generate-email-outreach-action"}
        />,
      );
      if (
        currentMessage?.type?.get() === "outreach" &&
        currentMessage?.status?.get() === "created" &&
        currentMessage?.channel?.get() === "email"
      ) {
        actions.push(
          <ActionButton
            label="Send Email Outreach"
            variant="contained"
            endIcon={<Send />}
            onClick={() => sendEmail(emailOutreachStep.pid)}
            tooltip={!lead.email ? "No email found" : "Send email"}
            disabled={!lead.email}
            key={"send-email-outreach-action"}
          />,
        );
      }
    }
    return <ButtonGroup>{actions}</ButtonGroup>;
  };
  // const renderCallActionButtons = () => {
  //   if (
  //     threadState === "research" ||
  //     !campaignData.sequence.find(
  //       (s) => s.step === "coldCallScript" && !s.template.startsWith("SKIP"),
  //     )
  //   )
  //     return;
  //   const actions = [];
  //   actions.push(
  //     <ActionButton
  //       label="Generate Cold Call Script"
  //       variant="outlined"
  //       startIcon={<AutoFixHigh />}
  //       onClick={() => generateMessage("coldCallScript", "call")}
  //       key={"generate-cold-call-action"}
  //     />,
  //   );
  //   if (
  //     thread.messages[msgIndex]?.type === "coldCallScript" &&
  //     thread.messages[msgIndex]?.status === "created"
  //   ) {
  //     actions.push(
  //       <ActionButton
  //         label="Archive Cold Call Script"
  //         variant="contained"
  //         endIcon={<Archive />}
  //         onClick={() => archiveCallScript()}
  //         key={"archive-cold-call-action"}
  //       />,
  //     );
  //   }
  //   return <ButtonGroup>{actions}</ButtonGroup>;
  // };

  const renderAIAssistant = useCallback(() => {
    if (
      currentMessage &&
      currentMessage.type &&
      currentMessage.type.get() === "outreach"
    )
      return (
        <Box className={styles.aiAssistantSwitch}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={showAI}
                  onChange={() => {
                    setShowAI(!showAI);
                  }}
                  size="small"
                />
              }
              label="AI"
            />
          </FormGroup>
        </Box>
      );
  }, [currentMessage, showAI]);
  return (
    <Box className={styles.footer}>
      <Box className={styles.innerFootContainer}>
        {!showAI ? (
          !loading ? (
            <Box className={styles.actionsContainer}>
              <Box key="linkedin-actions">{renderLinkedInActionButtons()}</Box>
              <Box key="email-actions">{renderEmailActionButtons()}</Box>
              {/* <Box key="call-actions">{renderCallActionButtons()}</Box> */}
            </Box>
          ) : (
            <CircularProgress />
          )
        ) : (
          <AiAssistantDialog />
        )}
        {renderAIAssistant()}
      </Box>
      {threadData.messages.length > 0 && <MessagesNav />}
    </Box>
  );
};
