import { useState } from "react";
import { useUser } from "../../contexts/UserContext";
import {
  Typography,
  Button,
  Avatar,
  Box,
  ButtonGroup,
  Tooltip,
} from "@mui/material";
import { PasswordResetModal } from "../../components/PasswordResetModal";
import { ProfileScanLinkedInModal } from "../../components/ProfileScanLinkedInModal";
import styles from "./Profile.module.scss";

export const MyInfo = () => {
  const { userData } = useUser();
  const [showProfileScanModal, setShowProfileScanModal] = useState(false);
  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);

  return (
    <Box className={styles.displayContainer}>
      {showProfileScanModal && (
        <ProfileScanLinkedInModal
          showModal={showProfileScanModal}
          setShowModal={setShowProfileScanModal}
        />
      )}
      {showPasswordResetModal && (
        <PasswordResetModal
          email={userData.email}
          open={showPasswordResetModal}
          setOpen={setShowPasswordResetModal}
        />
      )}
      <Box className={styles.displayContainerHeader}>
        <Typography variant="h6" gutterBottom>
          My Info
        </Typography>
      </Box>
      <Box className={styles.displayContainerBody}>
        <Box className={styles.displayContainerBodyFields}>
          <Typography variant="body1">
            First Name: <b>{userData.firstName}</b>
          </Typography>
          <Typography variant="body1">
            Last Name: <b>{userData.lastName}</b>
          </Typography>
          <Typography variant="body1">
            Email: <b>{userData.email}</b>
          </Typography>
          {userData.linkedin?.headline && (
            <Typography variant="body1">
              Headline: <b>{userData.linkedin?.headline}</b>
            </Typography>
          )}
          {userData.linkedin?.location && (
            <Typography variant="body1">
              Location: <b>{userData.linkedin?.location}</b>
            </Typography>
          )}
          {userData.linkedin?.industry && (
            <Typography variant="body1">
              Industry: <b>{userData.linkedin?.industry}</b>
            </Typography>
          )}
        </Box>
        <Box className={styles.displayContainerBodyProfilePic}>
          <Avatar
            alt={userData.firstName + " " + userData.lastName}
            src={userData.linkedin?.picture}
            sx={{ width: 56, height: 56 }}
            variant="rounded"
          />
        </Box>
      </Box>
      <Box className={styles.displayContainerFooter}>
        <ButtonGroup>
          <Tooltip title="Install the latest version of the Bella Chrome Extension">
            <Button
              variant="outlined"
              color="primary"
              size="small" // open link in new tab https://chromewebstore.google.com/detail/bella-browser-extension/ngbgjokppggjgonlclebdfoecfjippjm
              onClick={() => {
                window.open(
                  "https://chromewebstore.google.com/detail/bella-browser-extension/ngbgjokppggjgonlclebdfoecfjippjm",
                );
              }}
            >
              Get Bella Chrome Extension
            </Button>
          </Tooltip>
          <Tooltip title="Extract your most recent profile information from LinkedIn">
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => setShowProfileScanModal(true)}
            >
              Extract from My LinkedIn
            </Button>
          </Tooltip>
          <Tooltip title="Reset your account password">
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => setShowPasswordResetModal(true)}
            >
              Reset Account Password
            </Button>
          </Tooltip>
        </ButtonGroup>
      </Box>
    </Box>
  );
};
