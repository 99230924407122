import * as React from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import {
  Drawer as MuiDrawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  Campaign,
  AccountBox,
  CurrencyExchange,
  ManageHistory,
  QuestionAnswer,
} from "@mui/icons-material";
import { ShopModal } from "../ShopModal";

const drawerWidth = "fit-content";

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.easeIn,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  position: "relative",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  position: "relative",
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  backgroundColor: "#5457fcff",
  borderRadius: "0 0 10px 0",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

interface MainMenuProps {
  activePage: string;
  setActivePage: (page: string) => void;
}

const bellaMenuItems = [
  {
    name: "Profile",
    icon: <AccountBox />,
  },
  {
    name: "Campaigns",
    icon: <Campaign />,
  },
  {
    name: "Sequences",
    icon: <ManageHistory />,
  },
  {
    name: "Chat Bot",
    icon: <QuestionAnswer />,
  },
];

const BellaMenuItem = (props: {
  name: string;
  icon: JSX.Element;
  onClick: () => void;
  active: boolean;
}) => {
  const libSx = {
    minHeight: 48,
    justifyContent: open ? "initial" : "center",
    px: 2.5,
    color: "white",
    "&:hover": {
      backgroundColor: "#5470fc",
    },
  };

  const liSx = {
    minWidth: 0,
    mr: open ? 3 : "auto",
    justifyContent: "center",
    color: "white",
  };
  return (
    <ListItemButton sx={libSx} onClick={props.onClick} selected={props.active}>
      <ListItemIcon sx={liSx}>{props.icon}</ListItemIcon>
      <ListItemText primary={props.name} sx={{ opacity: open ? 1 : 0 }} />
    </ListItemButton>
  );
};

export function MainMenu(props: MainMenuProps) {
  const [open, setOpen] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);

  return (
    <Drawer
      variant="permanent"
      open={open}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      elevation={3}
      transitionDuration={1000}
    >
      {showModal && (
        <ShopModal showModal={showModal} setShowModal={setShowModal} />
      )}
      <List
        sx={{
          backgroundColor: "#5457fc",
        }}
      >
        {bellaMenuItems.map((item, idx) => (
          <BellaMenuItem
            key={idx}
            name={item.name}
            icon={item.icon}
            onClick={() => props.setActivePage(item.name.toLowerCase())}
            active={props.activePage === item.name.toLowerCase()}
          />
        ))}
        <BellaMenuItem
          key={bellaMenuItems.length + 1}
          name={"Credits"}
          icon={<CurrencyExchange />}
          onClick={() => setShowModal(true)}
          active={false}
        />
      </List>
    </Drawer>
  );
}
