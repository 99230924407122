import React, { useState } from "react";
import {
  Box,
  Alert,
  Button,
  TextField,
  DialogContentText,
  CircularProgress,
  Typography,
  List,
  ListItem,
  Avatar,
  Link,
  Grid,
  Tooltip,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";

import { Visibility, VisibilityOff } from "@mui/icons-material";

import { signUp, confirmSignUp, resendSignUpCode } from "aws-amplify/auth";
import { SignUpOutput, ConfirmSignUpOutput } from "aws-amplify/auth";
import { logInUser } from ".";
import bellaIcon from "../../assets/bellaIcon.png";
import { callAPI } from "../../api";

type RegistrationForm = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  linkedin: string;
  responseError?: string;
};

export const PasswordHint = () => (
  <Box
    sx={{
      fontSize: "0.7rem",
      flex: 1,
      p: 1,
    }}
    p={2}
  >
    <Typography variant="body2">Password requirements:</Typography>
    <List dense={true}>
      <ListItem>- Minimum length: 8 character(s)</ListItem>
      <ListItem>- Contains at least 1 number</ListItem>
      <ListItem>- Contains at least 1 uppercase letter</ListItem>
      <ListItem>- Contains at least 1 lowercase letter</ListItem>
    </List>
  </Box>
);

export function RegisterDialog(props: { close: () => void }) {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<RegistrationForm | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState([]);
  const [verificationCodeSent, setVerificationCodeSent] = useState(false);
  const [verificationCode, setVerificationCode] = useState(null);
  const [passwordAnchorEl, setPasswordAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleShowPasswordHint = (event: React.FocusEvent<HTMLElement>) => {
    setPasswordAnchorEl(passwordAnchorEl ? null : event.currentTarget);
  };

  const handleVerificationCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setVerificationCode(e.target.value);
  };

  const handleClose = () => {
    setFormData(null);
    setErrors([]);
    setLoading(false);
    setVerificationCodeSent(false);
    props.close();
  };

  const passwordOk = () => {
    return (
      formData?.password &&
      formData?.password.length >= 8 &&
      /[A-Z]/.test(formData.password) &&
      /[a-z]/.test(formData.password) &&
      /\d/.test(formData.password)
    );
  };

  const validate = () => {
    const tempErrors = [];
    if (!formData?.firstName) tempErrors.push("First name is required.");
    if (!formData?.lastName) tempErrors.push("Last name is required.");
    if (!formData?.email) {
      tempErrors.push("Email is required.");
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.push("Email is not valid.");
    } else if (
      [
        "gmail",
        "yahoo",
        "hotmail",
        "aol",
        "live",
        "yandex",
        "live",
        "wanadoo",
        "msn",
        "mail",
      ].includes(formData.email.split("@")[1].split(".")[0])
    ) {
      tempErrors.push(
        "Personal email is not allowed. Please use your business email.",
      );
    }
    if (!formData?.password) tempErrors.push("Password is required.");
    else {
      if (formData.password.length < 8)
        tempErrors.push("Password must be at least 8 characters long.");
      if (!/\d/.test(formData.password))
        tempErrors.push("Password must contain at least one number.");
      if (!/[A-Z]/.test(formData.password))
        tempErrors.push("Password must contain at least one uppercase letter.");
      if (!/[a-z]/.test(formData.password))
        tempErrors.push("Password must contain at least one lowercase letter.");
    }
    if (formData?.password !== formData?.confirmPassword)
      tempErrors.push("Passwords do not match.");
    setErrors(tempErrors);
    return tempErrors.length === 0;
  };

  const checkUserExists = async () => {
    const res: { exists: boolean } = await callAPI("GET", "/check_user", {
      email: formData.email,
    });
    if (res.exists)
      setErrors(["User with this email already exists. Please log in."]);
    return res.exists;
  };

  const sendVerificationCode = async () => {
    if (!validate()) return;
    setLoading(true);
    if (await checkUserExists()) return;
    try {
      const res: SignUpOutput = await signUp({
        username: formData.email,
        password: formData.password,
        options: {
          userAttributes: {
            given_name: formData.firstName,
            family_name: formData.lastName,
            email: formData.email,
          },
        },
      });
      console.log("User signed up:", res);
      if (res.nextStep?.signUpStep !== "CONFIRM_SIGN_UP" || !res.userId)
        throw new Error("Incorrect response from Cognito.");
      setVerificationCodeSent(true);
    } catch (error) {
      if (error.message === "User already exists") {
        await resendSignUpCode({
          username: formData.email,
        });
        setVerificationCodeSent(true);
      } else {
        console.error("Error signing up:", error);
        setErrors([error]);
      }
    } finally {
      setLoading(false);
    }
  };

  async function confirmAndRegister() {
    if (!verificationCode) return;
    setLoading(true);
    try {
      const res: ConfirmSignUpOutput = await confirmSignUp({
        username: formData.email,
        confirmationCode: verificationCode,
      });
      console.log("User confirmed by Cognito: ", res);
    } catch (error) {
      console.log(error);
      setErrors(["Verification code is incorrect. Please try again."]);
      setLoading(false);
      return false;
    }
    try {
      await createUserInBackend();
      await logInUser(formData.email, formData.password);
    } catch (error) {
      console.log(error);
      setErrors(["Something went wrong. Please contact Bella team."]);
    } finally {
      handleClose();
    }
  }

  async function createUserInBackend() {
    if (!validate()) return;
    try {
      await callAPI("POST", "/register", {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
      });
    } catch (e) {
      console.error(e);
      setErrors([e.message]);
    }
  }

  return (
    <Box
      sx={{
        my: 8,
        mx: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: "secondary.main" }} src={bellaIcon} />
      <Typography component="h1" variant="h5">
        Sign Up
      </Typography>
      {!verificationCodeSent ? (
        <Box sx={{ mt: 1, p: 4 }} className={"raised-tile"}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                name="firstName"
                value={formData?.firstName || ""}
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                value={formData?.lastName || ""}
                autoComplete="family-name"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Business Email"
                name="email"
                value={formData?.email || ""}
                autoComplete="email"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Tooltip title={<PasswordHint />} placement="top">
                <FormControl fullWidth sx={{ mt: 1 }}>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <OutlinedInput
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    value={formData?.password || ""}
                    autoComplete="new-password"
                    onChange={handleChange}
                    onFocus={handleShowPasswordHint}
                    onBlur={handleShowPasswordHint}
                    error={!passwordOk()}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={(e) => e.preventDefault()}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth sx={{ mt: 1 }}>
                <InputLabel htmlFor="confirmPassword">
                  Confirm Password
                </InputLabel>
                <OutlinedInput
                  label="Confirm Password"
                  name="confirmPassword"
                  type={showPassword ? "text" : "password"}
                  id="confirmPassword"
                  value={formData?.confirmPassword || ""}
                  onChange={handleChange}
                  fullWidth
                  required
                  error={formData?.confirmPassword !== formData?.password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  // helperText={
                  //   formData?.confirmPassword !== formData?.password &&
                  //   "Passwords do not match."
                  // }
                />
              </FormControl>
            </Grid>
          </Grid>
          {errors.map((e, idx) => (
            <Alert key={idx} severity="error">
              {e}
            </Alert>
          ))}
          {loading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <Button
            onClick={sendVerificationCode}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={
              loading || formData?.confirmPassword !== formData?.password
            }
          >
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link
                variant="body2"
                component="button"
                onClick={() => {
                  handleClose();
                }}
              >
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box sx={{ mt: 3 }}>
          <DialogContentText>
            A verification code has been sent to your email{" "}
            <b>{formData?.email}</b> from our support team. Please enter it
            below:
          </DialogContentText>
          <TextField
            label="Verification Code"
            name="verificationCode"
            value={verificationCode || ""}
            onChange={handleVerificationCodeChange}
            fullWidth
            margin="normal"
            required
          />
          {errors.map((e, idx) => (
            <Alert key={idx} severity="error">
              {e}
            </Alert>
          ))}
          {loading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <Button
            onClick={confirmAndRegister}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={!verificationCode || loading}
          >
            Confirm Email
          </Button>
        </Box>
      )}
    </Box>
  );
}
