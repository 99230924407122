import { useUser } from "../../contexts/UserContext";
import { Box, Link, Typography, Avatar } from "@mui/material";
import styles from "./Profile.module.scss";

export const MyCompanyInfo = () => {
  const { userData } = useUser();

  const fixUrl = (url: string) => {
    if (!url?.startsWith("http")) {
      return `http://${url}`;
    }
    return url;
  };

  return (
    <Box className={styles.displayContainer}>
      <Box className={styles.displayContainerHeader}>
        <Typography variant="h6" gutterBottom>
          My Company Info
        </Typography>
      </Box>
      <Box className={styles.displayContainerBody}>
        <Box className={styles.displayContainerBodyFields}>
          <Typography variant="body1">
            Company Name: <b>{userData.company?.name}</b>
          </Typography>
          <Typography variant="body1">
            Website:{" "}
            <Link
              href={fixUrl(
                userData.company?.website ||
                  userData.company?.linkedin?.website,
              )}
              underline="hover"
            >
              <b>
                {userData.company?.website ||
                  userData.company?.linkedin?.website}
              </b>
            </Link>
          </Typography>
          {userData.linkedin?.industry && (
            <Typography variant="body1">
              Industry: <b>{userData.company?.linkedin?.industry}</b>
            </Typography>
          )}
          {userData.linkedin?.location && (
            <Typography variant="body1">
              Location:{" "}
              <b>
                {userData.company?.location ||
                  userData.company?.linkedin?.countries}
              </b>
            </Typography>
          )}
        </Box>
        <Box className={styles.displayContainerBodyProfilePic}>
          <Avatar
            alt={userData.company?.name}
            src={userData.company?.linkedin?.picture}
            sx={{ width: 56, height: 56 }}
            variant="rounded"
          />
        </Box>
      </Box>
      <Box className={styles.displayContainerFooter}>
        <Typography variant="body2">
          {userData.company?.description ||
            userData.company?.linkedin?.description}
        </Typography>
      </Box>
    </Box>
  );
};
