import { useState, useMemo } from "react";
import { useThread } from "../../contexts/ThreadContext";
import { LeadConversationBox } from "../LeadConversationBox";
import { InsightsModal } from "./InsightsModal";
import { ChangeEmailDialog } from "../EmailDialog";
import { HubSpotIcon } from "../Icons";
import { useUser } from "../../contexts/UserContext";
import { HubSpotModal } from "../HubSpotModal";
import { Tooltip, Box } from "@mui/material";
import {
  Insights,
  Forum,
  AccountTree,
  Email,
  LinkedIn,
  Visibility,
  VisibilityOff,
  Phone,
} from "@mui/icons-material";

const Counter = ({
  icon,
  value = null,
  onClick = null,
  tooltipTitle = null,
}) => {
  const innerComponent = (
    <Box
      onClick={onClick}
      sx={{
        cursor: onClick ? "pointer" : "default",
      }}
    >
      {icon}
      {value}
    </Box>
  );
  return tooltipTitle ? (
    <Tooltip title={tooltipTitle}>{innerComponent}</Tooltip>
  ) : (
    innerComponent
  );
};

export const Counters = () => {
  const { userData } = useUser();
  const [showInsights, setShowInsights] = useState<boolean>(false);
  const { threadData, lead, changeVisibility } = useThread();
  const [showHubSpotDialog, setShowHubSpotDialog] = useState<boolean>(false);
  const [conversationOpen, setConversationOpen] = useState(false);
  const [changeEmailDialogOpen, setChangeEmailDialogOpen] = useState(false);

  const insights = threadData.insights.get();
  const insightsOk = useMemo(() => {
    if (!insights) return false;
    const keys = insights.map((i) => i.origin);
    return (
      keys.includes("lead_linkedin") &&
      keys.includes("company_linkedin") &&
      keys.includes("industry_news")
    );
  }, [insights]);

  const InsightsCounter = () => (
    <Counter
      tooltipTitle="Click to read insights about the prospect"
      value={insightsOk ? 3 : 0}
      icon={<Insights color={insightsOk ? "success" : "warning"} />}
      onClick={insightsOk ? () => setShowInsights(true) : null}
    />
  );

  const MessagesCounter = () => (
    <Counter
      value={
        threadData.messages?.filter((m) =>
          ["sent", "replied", "received"].includes(m.status.get()),
        ).length
      }
      tooltipTitle="Number of Sent/Received Messages"
      icon={<Forum />}
      // onClick={() => setConversationOpen(true)}
    />
  );

  const LISNDegreeCounter = () => (
    <Counter
      tooltipTitle="The connection degree with the lead on LinkedIn"
      value={lead.degree}
      icon={<AccountTree color={lead.degree == 1 ? "success" : "warning"} />}
    />
  );

  const LISNOpenProfileCounter = () => (
    <Counter
      icon={<LinkedIn color={lead.openProfile ? "success" : "warning"} />}
      tooltipTitle={
        lead.openProfile
          ? "The lead has an open LinkedIn profile meaning you can send them outreach without an accepted connection request. But it is a good practice to send CR first."
          : "The lead has a closed LinkedIn profile meaning you cannot send them messages without accepted connection request."
      }
    />
  );

  const HubSpotDialogCounter = () => {
    const tooltip = lead.hubSpotLeadId
      ? lead.firstName + " is in your HubSpot. Click to Update Hubspot Lead."
      : lead.firstName +
        " is not in your HubSpot." +
        " Click to Import Lead into HubSpot.";
    return (
      <Counter
        icon={
          <HubSpotIcon
            style={{
              width: "18px",
              ...(lead.hubSpotLeadId && {
                filter:
                  "invert(20%) sepia(30%) saturate(80%) hue-rotate(100deg) contrast(100%) brightness(80%)",
              }),
            }}
          />
        }
        tooltipTitle={tooltip}
        onClick={() => setShowHubSpotDialog(true)}
      />
    );
  };

  const EmailCounter = () => (
    <Counter
      icon={<Email color={lead.email ? "success" : "error"} />}
      tooltipTitle={
        lead.email
          ? `Lead's email is identified: ${lead.email}.`
          : "Lead has no email"
      }
      onClick={() => setChangeEmailDialogOpen(true)}
    />
  );

  const VisibilityCounter = () => (
    <Counter
      icon={
        threadData.hidden.get() ? (
          <VisibilityOff color="warning" />
        ) : (
          <Visibility color="primary" />
        )
      }
      tooltipTitle={threadData.hidden.get() ? "Unhide thread" : "Hide thread"}
      onClick={() => changeVisibility()}
    />
  );

  const PhoneCounter = () => {
    let phoneNumbers = lead.phoneNumbers || [];
    if (!Array.isArray(phoneNumbers)) {
      console.log("phoneNumbers", phoneNumbers);
      phoneNumbers = JSON.parse(phoneNumbers.replace(/'/g, '"'));
    }
    return (
      <Counter
        icon={<Phone color={phoneNumbers?.length > 0 ? "success" : "error"} />}
        tooltipTitle={
          phoneNumbers?.length > 0
            ? `Found lead's phone number(s): ${phoneNumbers.join("; ")}.`
            : "No phone number was found for the lead."
        }
      />
    );
  };
  return (
    <Box
      sx={{
        fontSize: "0.8rem",
        fontWeight: "bold",
        color: "#6089d1",
        position: "absolute",
        top: "5px",
        right: "5px",
        display: "flex",
        flexDirection: "row",
        gap: "5px",
      }}
    >
      {lead && (
        <LeadConversationBox
          open={conversationOpen}
          setOpen={setConversationOpen}
        />
      )}
      {insightsOk && showInsights && (
        <InsightsModal open={showInsights} setOpen={setShowInsights} />
      )}
      {lead && (
        <ChangeEmailDialog
          open={changeEmailDialogOpen}
          setOpen={setChangeEmailDialogOpen}
        />
      )}
      {showHubSpotDialog && (
        <HubSpotModal open={showHubSpotDialog} setOpen={setShowHubSpotDialog} />
      )}

      <InsightsCounter />
      <MessagesCounter />
      <LISNDegreeCounter />
      <LISNOpenProfileCounter />
      {userData.crmProvider === "hubspot" && <HubSpotDialogCounter />}

      <EmailCounter />
      <PhoneCounter />
      <VisibilityCounter />
    </Box>
  );
};
